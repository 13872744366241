<template>
  <main class="mt-0 main-content">
    <section>
      <div class="col-md-12 loginback" id="Login">
       <!-- <div class="col-md-8 p-0 parteVerde"> -->
        <div class="signup">
          <div class="signup-connect" style="display: none;">
            <h1 style="text-align: center">Sistema Hse</h1>
            <div class="col-md-12" style="text-align: center;">
              <img src="../assets/img/emp/Empresa.png" style="width: 300px !important"/>
            </div>
          </div>
          <div class="signup-classic">
            <div class="form">
              <div class="col-md-12" style="text-align: center;">
              <img src="../assets/img/emp/Empresa.png" style="width: 300px !important"/>
            </div>
              <diV style="text-align: center; color: white; font-size: 20px;  font-weight: 500;">
                <label>! Ingrese credenciales ¡</label>
              </diV>
              <fieldset class="username">
                <input
                  v-model="filtro.usuario"
                  type="text"
                  placeholder="USUARIO"
                  @keyup.enter="$refs.refClave.focus()"
                  @input="filtro.usuario = $event.target.value.toUpperCase()"
                  style="    border-radius: 30px !important; color: rgb(26 39 89) !important;font-weight: 500;"
                  ref="refUsuario" />
              </fieldset>
              <fieldset class="password">
                <input
                  v-model="filtro.clave"
                  type="password"
                  placeholder="CLAVE"
                  @keyup.enter="$refs.btnLogin.focus()"
                  style=" border-radius: 30px !important; color: rgb(26 39 89) !important; font-weight: 500; "
                  ref="refClave" />
              </fieldset>
              <button
                @click="Login()"
                class="btn"
                ref="btnLogin"
                style="border-radius: 30px !important; background: rgb(52, 71, 103)"
                v-if="!preloader">INGRESAR
              </button>
              <div v-show="preloader" class="container">
                <h5 style="color: blue">Ingresando</h5>
                <div class="bar-container">
                  <div class="bar"></div>
                  <div class="bar"></div>
                  <div class="bar"></div>
                  <div class="bar"></div>
                </div>
              </div>
            </div>
          </div>

        </div>
      <!-- </div> -->

      </div>
    </section>
  </main>
</template>

<script>
import { APP } from "../Content/base";
//import axios from "axios";

export default {
  el: "#Login",
  name: "Login",
  data() {
    return {
      preloader: false,
      filtro: {
        usuario: "",
        clave: "",
      },
    };
  },
  components: {},
  created() {
    const routeArr = this.$route.path.split("/");
    if (routeArr[1] === "login") {
      this.$store.state.showSidenav = false;
      this.$store.state.showNavbar = false;
      this.$store.state.showFooter = false;
    } else {
      this.$store.state.showSidenav = true;
      this.$store.state.showNavbar = true;
      this.$store.state.showFooter = true;
    }
  },
  mounted() {
    let _this = this;

    _this.$nextTick(() => {
      _this.$refs.refUsuario.focus();
    });
  },
  beforeUnmount() {
    this.$store.state.showSidenav = true;
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = true;
  },
  methods: {
    Login: async function () {
      let _this = this;

      var state = await _this.validarCampos();
      if (state) {
        _this.preloader = true;

        const request = {
          LoginUser: _this.filtro.usuario || "",
          passUser: _this.filtro.clave || "",
        };

        await this.axios
          .post("api/admin/user/v1/login", request)
          .then((response) => {
            // alert(response);
            if (response.data.EsCorrecto) {
              localStorage.clear();
              this.$store.state.isLogeado = true;
              localStorage.setItem(
                "SessionToken",
                JSON.stringify(
                  response.data ? response.data.Valor.tokenWeb : undefined
                )
              );
              localStorage.setItem(
                "Usuario",
                JSON.stringify(response.data ? response.data.Valor : undefined)
              );

              this.axios.defaults.headers.common["Authorization"] =
                "Bearer " + response.data ? response.data.Valor.tokenWeb : "";

              APP.msg.success(this.$toast, "BIENVENIDOS ...!");
              this.$router.push("/");
            } else {
              APP.msg.warning(this.$toast, response.data.Mensaje);
              _this.$refs.refUsuario.focus();
            }
          })
          .catch((error) => {
            //console.log(error);
            alert(error);
            localStorage.clear();
            this.$store.state.isLogeado = false;
            _this.preloader = false;
          });
        _this.preloader = false;
      }
    },
    validarCampos: async function () {
      let _this = this;
      var response = true;

      if (_this.filtro.usuario === "") {
        APP.msg.warning(this.$toast, "Ingrese Usuario..!");
        _this.$refs.refUsuario.focus();
        return (response = false);
      }
      if (_this.filtro.clave === "") {
        APP.msg.warning(this.$toast, "Ingrese Clave..!");
        _this.$refs.refClave.focus();
        return (response = false);
      }
      return response;
    },
  },
};
</script>

<style scoped>
/* .parteVerde{
  position: fixed;
    height: 100%;
    clip-path: polygon( 0 0, 100% 0, 85% 0%, 100% 110%, 100% 100%, 100% 100%, 0% 100%, 0 20% );
    background: #589732;
} */

.loginback {
  /* background: url(../../src/assets/img/login.png) center center/cover */
  background: url(../../src/assets/img/transporte.jpg) !important;
    height: 100%;
    position: fixed;
    width: 100%;
    background-repeat: no-repeat !important;
    background-attachment: fixed !important;
    background-size: cover !important;
}

.loginP {
  background: url(../../src/assets/img/logiF.jpg) center center/cover no-repeat !important;
  /* width: 500px; */
  height: 100%;
  position: fixed;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  font-family: Open Sans;
}

body {
  background: #f4fde7;
}

/*--------------------
Buttons
--------------------*/
.btn {
  display: block;
  background: #bded7d;
  color: white;
  text-decoration: none;
  margin: 20px 0;
  padding: 15px 15px;
  border-radius: 30px;
  position: relative;
}
.btn::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.2s ease-in-out;
  box-shadow: inset 0 3px 0 rgba(0, 0, 0, 0), 0 3px 3px rgba(0, 0, 0, 0.2);
  border-radius: 30px !important;
}
.btn:hover::after {
  background: rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 3px 0 rgba(0, 0, 0, 0.2);
}

.btn-social {
  padding-left: 64px;
  position: relative;
  z-index: 1;
}
.btn-social .fa {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 50px;
  height: 100%;
  text-align: center;
  background: rgba(0, 0, 0, 0.1);
  line-height: 3.2;
  border-radius: 5px 0 0 5px;
}

.btn-facebook {
  background-color: #3b5897;
}

.btn-twitter {
  background-color: #53abee;
}

.btn-google {
  background-color: #de4e3b;
}

.btn-linkedin {
  background-color: #00a1db;
}

/*--------------------
Form
--------------------*/
.form fieldset {
  border: none;
  padding: 0;
  margin: 20px 0;
  position: relative;
}
.form fieldset input {
  width: 100%;
  height: 48px;
  color: #333333;
  padding: 15px 40px 15px 15px;
  border-radius: 5px;
  font-size: 14px;
  outline: none !important;
  border: 1px solid rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.2);
  vertical-align: top;
}
.form button {
  width: 100%;
  outline: none !important;
  background: linear-gradient(-5deg, #79b52c, #94d63d);
  border: none;
  text-transform: uppercase;
  font-weight: bold;
  box-shadow: 0 3px 0px rgba(115, 136, 89, 0.2);
  text-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
}

/*--------------------
Signup
--------------------*/
.signup {
  /* position: relative;
  margin-top: 23% !important;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  width: 800px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 3px 25px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  display: flex; */

   
    /* transform: translate(40%, 70%); */
    transform: translate(51%, 30%);
    position: relative;
    z-index: 1;
    width: 372px;
    background: linear-gradient(45deg, black, rgba(0, 0, 0, 0.1));
    border-radius: 10px;
    left: 23%;
    box-shadow: 0 3px 25px rgba(0, 0, 0, 0.2);
}

.signup-connect,
.signup-classic {
  /* width: 50%; */
  padding: 30px 30px;
}

.signup-connect {
  /* background: linear-gradient(134deg, #fbce50, #e08106); */
  background: linear-gradient(134deg, #4c4f62, #070813);
  color: white;
}
.signup-connect h1 {
  font-size: 30px;
  margin-top: 10px;
  margin-bottom: 40px;
  text-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
}

.signup-classic h2 {
  font-size: 16px;
  font-weight: normal;
  margin-top: 23px;
  margin-bottom: 43px;
  text-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
}
.signup-classic fieldset::after {
  content: "";
  font-family: FontAwesome;
  position: absolute;
  right: 15px;
  top: 17px;
  z-index: 2;
  width: 20px;
  color: rgba(0, 0, 0, 0.2);
  text-align: center;
}
.signup-classic fieldset.email::after {
  content: "";
}
.signup-classic fieldset.password::after {
  content: "";
}

@media (max-width:980px) {
  .signup{
    top: 30%;
  }
}

/* @media (max-width:480px),  */
@media (max-width:767px) {
  .loginback {
  /* background: url(../../src/assets/img/login.png) center center/cover */
  background: url(../../src/assets/img/celularVerde.png) center center/cover !important;
    height: 100%;
    position: fixed;
    width: 100%;
    background-repeat: no-repeat !important;
    /* background-attachment: fixed !important;
    background-size: cover !important; */
}
  /* .loginback {
      width: 100%;
  } */

  .signup {
    /* top: 0%;
    position: relative;
    margin-top: 23% !important;
    left: 50%;
    width: 90%;
    transform: translate(-50%, -50%);
    z-index: 1;
    margin-top: 90% !important;
    background: white;
    border-radius: 10px;
    box-shadow: 0 3px 25px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    display: block; */
    position: relative;
    z-index: 1;
    width: 372px;
    background: transparent;
    border-radius: 10px;
    left: 3%;
    top: 37%;
  }

  .signup-connect{
    width: 100%;
    padding: 30px 20px;
  }
  .signup-classic {
    width: 100%;
    padding: 30px 20px;
    /* background: white; */
  }

}


/* preloader */
@import url(https://fonts.googleapis.com/css?family=Lato:300,400);
body {
  background-color: #2a2a2a;
  margin: 0;
}
.container {
  text-align: center;
  font-family: "Lato";
  color: #fff;
}

/* linear loading */
.bar-container {
  position: relative;
  height: 5px;
  width: 100%;
  background-color: #bdbdbd;
}

.bar {
  content: "";
  display: inline;
  position: absolute;
  height: 100%;
  width: 0;
  right: 0;
}

.bar:nth-child(1) {
  background-color: #006495;
  animation: linear_loader 3s linear 1s infinite;
}

.bar:nth-child(2) {
  background-color: #006495;
  animation: linear_loader 3s linear 2s infinite;
}

.bar:nth-child(3) {
  background-color: #006495;
  animation: linear_loader 3s linear 3s infinite;
}

@keyframes linear_loader {
  0% {
    right: 100%;
    width: 10%;
  }
  30% {
    right: 0%;
    width: 40%;
  }
  50% {
    right: 0%;
    width: 0%;
  }
  80% {
    right: 0%;
    width: 0%;
  }
  100% {
    right: 0%;
    width: 0%;
  }
}
</style>