<template>
  <aside
    class=" overflow-auto border-0 sidenav navbar navbar-vertical navbar-expand-xs  fixed-start  bg-white "
    id="sidenav-main"
    style="overflow: hidden !important; z-index: 999 !important;"
  >
    <hr class="mt-0 horizontal dark" />
    <div class="d-flex flex-column flex-shrink-0 text-bg-dark" style=" height: calc(100vh); margin-top: -17px;">
      <div class="p-3" style="background-color: rgb(52, 71, 103);     padding: 10px 16px !important;">
        <a class="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none ">
          <img src="../assets/img/emp/Empresa.png" style="width: 100%; height: 37px;"/>
          <li class="px-3 nav-item d-flex align-items-center verLista menuBar" >
            <a class="p-0 nav-link text-white" @click="toggleSidenavVer">
              <i class="fa fa-arrow-circle-left fa-2x "></i>
            </a>
          </li>
        </a>
      </div>

      <div class="p-3">
        <ul class="nav nav-pills flex-column mb-auto">
          <li>
            <div class="p-10" style="padding-bottom: 3px !important">
              <input  v-model="search" class="form-control input-sm" placeholder="🔍️ Buscar en el menú" type="text" style="    font-size: 14px;"/>
            </div>
          </li>
          <li class="nav-item navitempad" @click="openCollapse('dashboard')">
            <router-link to="/dashboard" class="nav-link" style="display: flex">
              <div class=" icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center " style="line-height: 10px">
                <i class="fa fa-dashboard text-new-ligth text-sm opacity-10 " style="margin-right: 5px" ></i>
              </div>
              <span class="nav-link-text ms-1"
                >Dashboard
              </span>
            </router-link>
          </li>
          <li class="nav-item navitempad" v-show="H.findIndex((o) => { return o.letshow }) !== -1">
            <a @click="openCollapse('collapseExample0')"
              data-bs-toggle="collapse"
              href="#collapseExample0"
              aria-expanded="false"
              aria-controls="collapseExample0"
              class="nav-link"
              :class=" getRoute() == 'controlesDiarios' || getRoute() == 'controlHoraSu' || getRoute() == 'registroActosCondi' || getRoute() == 'controlHseViajeProg'? 'active' : ' '"
              style="display: flex"
              ref="HMenu">
              <div class="text-center d-flex align-items-center justify-content-center" style="line-height: 10px">
                 <i class=" fa fa-bus text-new-ligth text-sm opacity-10 "></i>
              </div>
              <span class="nav-link-text ms-1" style="width: 90%;">HSE</span>
              <span><i class="fa fa-caret-down"></i></span>
            </a>
            <div class="collapse" id="collapseExample0">
              <ul class="nav ms-4">
                <li v-for="(item, key) in H" class="nav-item" v-bind:key="key" v-show="item.letshow">
                  <router-link :to="item.url" class="submenu nav-link">
                    <span class="sidenav-mini-icon" style="margin-right: 5px"><b>{{ key + 1 }}</b></span>
                    <span class="sidenav-normal">{{ item.label }}</span>
                  </router-link>
                </li>
              </ul>
            </div>
          </li>
          <li class="nav-item navitempad" v-show="C.findIndex((o) => { return o.letshow }) !== -1">
            <a  @click="openCollapse('collapseExample1')"
              data-bs-toggle="collapse"
              href="#collapseExample1"
              aria-expanded="false"
              aria-controls="collapseExample1"
              class="nav-link"
              :class="getRoute() == 'configHoraSue' || getRoute() == 'registrosDiarios' ? 'active' : ' '"
              style="display: flex"
              ref="CMenu">
              <div class="text-center d-flex align-items-center justify-content-center" style="line-height: 10px">
                 <i class=" fa fa-gear text-new-ligth text-sm opacity-10 "></i>
              </div>
              <span class="nav-link-text ms-1" style="width: 90%;">Configuración</span>
              <span><i class="fa fa-caret-down"></i></span>
            </a>
            <div class="collapse" id="collapseExample1">
              <ul class="nav ms-4">
                <li v-for="(item, key) in C" class="nav-item" v-bind:key="key">
                  <router-link :to="item.url" class="submenu nav-link">
                    <span class="sidenav-mini-icon" style="margin-right: 5px"><b>{{ key + 1 }}</b></span>
                    <span class="sidenav-normal">{{ item.label }}</span>
                  </router-link>
                </li>
              </ul>
            </div>
          </li>
          <!-- <li class="nav-item navitempad" v-show="M.findIndex((o) => { return o.letshow }) !== -1">
            <a @click="openCollapse('collapseExample1')"
              data-bs-toggle="collapse"
              href="#collapseExample1"
              aria-expanded="false"
              aria-controls="collapseExample1"
              class="nav-link"
              :class="getRoute() == 'claseBrevete'? 'active' : ' '"
              style="display: flex"
              ref="MMenu">
              <div class=" icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center" style="line-height: 10px">
                <i class=" fa fa-gear  text-new-ligth text-sm opacity-10"></i>
              </div>
              <span class="nav-link-text ms-1" style="width: 90%;">Mantenimientos</span>
              <span><i class="fa fa-caret-down"></i></span>
            </a>
            <div class="collapse" id="collapseExample1">
              <ul class="nav ms-4">
                <li v-for="(item, key) in M" class="nav-item" v-bind:key="key">
                  <router-link :to="item.url" class="submenu nav-link">
                    <span class="sidenav-mini-icon" style="margin-right: 5px"><b>{{ key + 1 }}</b></span>
                    <span class="sidenav-normal">{{ item.label }}</span>
                  </router-link>
                </li>
              </ul>
            </div>
          </li> -->
        </ul>
        <hr />
      </div>
    </div>
  </aside>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  name: "LayoutSidebar",
  props: {},
  data() {
    return {
      search:"",
      H: [
        {id: "H1",existeMenu: false, label: "Controles diarios",url: "/controlesDiarios",letshow: false,},
        {id: "H2",existeMenu: false, label: "Control Horas de Sueño",url: "/controlHoraSu",letshow: false,},
        // {id: "H3",existeMenu: false, label: "Registro de actos de condiciones RACS",url: "/registroActosCondi",letshow: false,},
        {id: "H4",existeMenu: false, label: "Control Hse Viajes y/o Programaciones",url: "/controlHseViajeProg",letshow: false,}
      ],
      C: [
        {id: "C1", existeMenu: false, label: "Config. Horas de Sueño",url: "/configHoraSue",letshow: false,},
        {id: "C2", existeMenu: false, label: "Registros Diarios",url: "/registrosDiarios",letshow: false,},
      ],
      M: [
        {id: "M1", existeMenu: false, label: "Clase Brevete",url: "/claseBrevete",letshow: false,}
      ],
      Oldvalue: '',
      ExisteMenuH: false,
      ExisteMenuC: false,
      ExisteMenuM: false,
    };
  },
  components: {},
  async mounted(){
   let _this = this;
    await _this.hacerMatchMenu();
  },
  methods: {
    ...mapMutations(["toggleSidenavVer"]),
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
    getMenu() {
      var estado = '';
      const routeArr = this.$route.path.split("/");
      for (var i = 0; i < this.M.length; i++) {
        if (estado === '') {
          if(this.M[i].url === routeArr[1]) {
            estado = 'EXISTE';
          }
        }
      }
      return estado;
    },
    openCollapse(_id){

      if(_id !== 'dashboard'){
        for (var i = 0; i < 2; i++) {
          var myCollapsible = document.getElementById(('collapseExample' + i));
          myCollapsible.className = 'collapse hide';
        }

        if(this.Oldvalue != _id){
            var myCollapsible2 = document.getElementById(_id);
              myCollapsible2.className = 'collapse show';
        }
      }else{
        for (var e = 0; e < 2; e++) {
          var myCollapsible3 = document.getElementById(('collapseExample' + e));
          myCollapsible3.className = 'collapse hide';
        }
      }
        this.Oldvalue = _id;
    },
    hacerMatchMenu: async function () {
            let _this = this;

           // var _Menu = _PermisosUsuario;
           // for (var i = 0; i < _Menu.length; i++) { 

                _this.H.forEach(e => {
                   // if (e.label.toLowerCase() === _Menu[i].Descripcion.toLowerCase()) {     
                        e.letshow = true;
                        e.existeMenu = true;
                        _this.ExisteMenuH = true;
                  //  }
                });

                _this.C.forEach(e => {
                   // if (e.label.toLowerCase() === _Menu[i].Descripcion.toLowerCase()) {     
                        e.letshow = true;
                        e.existeMenu = true;
                        _this.ExisteMenuC = true;
                  //  }
                });

              
           // }
    },
    cleanOptions: function (show = false) {
        let _this = this;
        if (_this.ExisteMenuH) {
            _this.H.forEach(e => {
                e.letshow = show;
            });
        }

        if (_this.ExisteMenuC) {
            _this.C.forEach(e => {
                e.letshow = show;
            });
        }
       
    },

  },
  watch: {
    'search': async function (newVal, oldVal) {
            let _this = this;

            if (typeof oldVal != undefined) {
                if (newVal) {

                    _this.cleanOptions();
                    //await _this.hacerMatchMenu();

                    if (_this.ExisteMenuH) {
                        _this.H.forEach(e => {
                            if (e.existeMenu) {
                                if (e.label.toLowerCase().includes(newVal.toLowerCase()) || e.id.toLowerCase() == newVal.toLowerCase()) {
                                    if (e.id.toLowerCase() == newVal.toLowerCase()) {
                                        _this.$refs.HMenu.click();
                                    }
                                    e.letshow = true;
                                }
                            }
                        });
                    }

                    if (_this.ExisteMenuC) {
                        _this.C.forEach(e => {
                            if (e.existeMenu) {
                                if (e.label.toLowerCase().includes(newVal.toLowerCase()) || e.id.toLowerCase() == newVal.toLowerCase()) {
                                    if (e.id.toLowerCase() == newVal.toLowerCase()) {
                                        _this.$refs.CMenu.click();
                                    }
                                    e.letshow = true;
                                }
                            }
                        });
                    }
                   
                } else {
                    await _this.hacerMatchMenu();
                }
            }
    }
  },

};
</script>

<style scoped>
.menuBar{
  left: 79%;
  position: absolute;
  display: none !important;
}

@media (max-width:767px) {
  .menuBar{
    left: 79%;
    position: absolute;
    display: block !important;
  }
}


.navbar-vertical.navbar-expand-xs {
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  max-width: 15.625rem !important;
  overflow-y: auto;
  padding: 0;
  box-shadow: none;
  z-index: 9999;
}
.navbar-vertical.navbar-expand-xs.fixed-start {
  left: 0;
}
.sidenav {
  z-index: 999;
}
.bg-white {
  background-color: #fff !important;
}
.border-0 {
  border-width: 0 !important;
}
.border-0 {
  border: 0 !important;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-right: 1rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
}
.main-content,
.sidenav {
  transition: all 0.2s ease-in-out;
}

.navbar-vertical.bg-white {
  box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%);
}

@media (min-width: 1200px) {
  .sidenav:hover {
    max-width: 15.625rem;
  }
}

.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}

.b-example-divider {
  height: 3rem;
  background-color: rgba(0, 0, 0, 0.1);
  border: solid rgba(0, 0, 0, 0.15);
  border-width: 1px 0;
  box-shadow: inset 0 0.5em 1.5em rgba(0, 0, 0, 0.1),
    inset 0 0.125em 0.5em rgba(0, 0, 0, 0.15);
}

.b-example-vr {
  flex-shrink: 0;
  width: 1.5rem;
  height: 100vh;
}

.bi {
  vertical-align: -0.125em;
  fill: currentColor;
}

.nav-scroller {
  position: relative;
  z-index: 2;
  height: 2.75rem;
  overflow-y: hidden;
}

.nav-scroller .nav {
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  margin-top: -1px;
  overflow-x: auto;
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
   background-color: rgb(52, 71, 103);
  /* background-color: #589732; */
  color: white;
}

.nav-link {
  /* color: #2b4165; */
  color: #f8f9fa;
  font-size: 13px !important;
  font-weight: 500 !important;
}
a.active.router-link-exact-active.submenu.nav-link {
  /* background-color: white !important; */
  background-color: #212529 !important;
   color: #0a58ca !important;
  /* color:#589732 !important */
}

.text-new-ligth{
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light),var(--bs-text-opacity))!important;
}

</style>