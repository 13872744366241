<template>
    <div class="Container">
    <div style="margin-bottom: 5px !important">
      <button @click="Nuevo()" class="btn btn-success" style="margin-right: 3px !important" data-bs-toggle="modal" data-bs-target="#exampleModalHora">
        <span style="margin-right: 5px"><i class="fa fa-arrow-right"></i></span>Nuevo
      </button>
      <button @click="geBusqueda()" class="btn btn-primary" style="margin-right: 3px !important">
        <span style="margin-right: 5px"><i class="fa fa-search"></i></span>Buscar
      </button>
    </div>
    <div class="White-Box">
      <div class="form-div" style="margin-bottom: 10px">
        
        <div class="col-md-9 col-sm-9 col-xs-12 separate-in-form">
          <label class="control-label label-sm">Buscar por Descripción</label>
          <div class="">
            <input
              v-model="filtro.Descripcion"
              name="Descripcion"
              class="form-control input-sm"
              placeholder="Ingrese Descripción"
              @input="filtro.Descripcion = $event.target.value.toUpperCase()"
              ref="refDescripcion"
              autocomplete="off"
            />
          </div>
        </div>
        <div class="col-md-3 col-sm-3 col-xs-12 separate-in-form">
          <label class="control-label label-sm">Tipo Operación</label>
          <div class="">
            <v-select
              v-model="vselect.Operacion"
              name="Operacion"
              :clearable="true"
              class="v-select-form"
              label="Descripcion"
              placeholder="--Seleccione--"
              :options="list.Operacion"
              ref="refOperacion">
            </v-select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class=" col-md-12 col-sm-12 col-xs-12  p-l-0 p-r-0 m-b-10 paginate-padding" style="overflow-x: auto" >
          <table class="  table table-responsive table-hover table-bordered  custom-table" >
            <thead>
              <tr>
                <th>CODIGO</th>
                <th>DESCRIPCIÓN</th>
                <th>OPERACIÓN</th>
                <th>CRITICO</th>
                <th>ALTO</th>
                <th>MODERADO</th>
                <th>BAJO</th>
                <th>ACCIONES</th>
              </tr>
            </thead>
            <tbody>
              <tr 
               v-for="(item, key) in list.TablaDetalle" :key="key + 1"
               v-show="(pag - 1) * NUM_RESULTS <= key && pag * NUM_RESULTS > key">
                <td class="text-left">{{ item.Code }}</td>
                <td class="text-left">{{ item.Name }}</td>
                <td class="text-left">{{ item.Operacion }}</td>
                <td class="text-center">{{ item.Critical }}</td>
                <td class="text-center">{{ item.Hight }}</td>
                <td class="text-center">{{ item.Modest }}</td>
                <td class="text-center">{{ item.Low }}</td>
                <td class="text-center" style="text-align: center">
                  
                    <a
                      href="javascript:void(0)"
                      title="Ver Documento"
                      class="text-primary m-r-10"
                      id="Info"
                      style="color: #178719; margin-right: 5px !important"
                      data-bs-toggle="modal" 
                      data-bs-target="#exampleModalHora"
                      @click="openModal(item)"
                    >
                      <span
                        class="fa fa-edit"
                        style="font-size: 1.6em"
                      ></span>
                    </a>
                    <a
                      href="javascript:void(0)"
                      title="Ver Documento"
                      class="text-danger m-r-10"
                      id="Info"
                      style="color: #178719"
                      @click="Delete(item)"
                    >
                      <span
                        class="fa fa-trash-o"
                        style="font-size: 1.6em"
                      ></span>
                    </a>
                 
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          class="col-md-12 col-sm-12 col-xs-12 p-0"
          style="margin-top: 10px !important"
        >
          <nav aria-label="Page navigation" class="text-center">
            <ul class="pagination text-center pull-right">
              <li>
                <a
                  href="#" aria-label="Previous" v-show="pag != 1"
                  @click.prevent="pag -= 1" style="padding: 0px 0px 0px 0px" >
                  <span aria-hidden="true">
                    <button class="btn btn-info" style="height: 25px; line-height: 10px; color: #344767">
                      <i class="fa fa-angle-double-left"></i>
                    </button>
                  </span>
                </a>
              </li>
              <li>
                <a
                  href="#" aria-label="Next" v-show="(pag * NUM_RESULTS) / list.TablaDetalle.length < 1"
                  @click.prevent="pag += 1" style="padding: 0px 10px 0px 5px" >
                  <span aria-hidden="true">
                    <button class="btn btn-info" style="height: 25px; line-height: 10px; color: #344767" >
                      <i class="fa fa-angle-double-right"></i>
                    </button>
                  </span>
                </a>
              </li>
            </ul>
          </nav>
        </div>

      </div>
    </div>
  </div>

<!-- Modal -->
  <div
    class="modal fade"
    id="exampleModalHora"
    tabindex="-1"
    data-bs-backdrop="static"
    aria-labelledby="exampleModalHoraLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalHoraLabel">
            Nuevo Registro
          </h5>
          <button
            @click="cleanField()"
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="form-div col-md-12 col-sm-12 col-xs-12 p-0">
            <div class="col-md-3 col-sm-3 col-xs-12 separate-in-form">
              <label class="control-label label-sm">Codigo</label>
              <div class="">
                <input
                  v-model="filtro.Popup.Codigo"
                  name="Codigo"
                  class="form-control input-sm"
                  placeholder="0000"
                  ref="refCodigo"
                  @input="filtro.Popup.Codigo = $event.target.value.toUpperCase()"
                  @keyup.enter="nextFocus($refs.refDescripcionAnx, 'input', 100)"
                  autocomplete="off"
                  maxlength="4"/>
              </div>
            </div>
            <div class="col-md-9 col-sm-9 col-xs-12 separate-in-form">
              <label class="control-label label-sm">Descripción</label>
              <div class="">
                <input
                  v-model="filtro.Popup.Descripcion"
                  name="Descripcion"
                  class="form-control input-sm"
                  placeholder=""
                  ref="refDescripcionAnx"
                  @input="filtro.Popup.Descripcion = $event.target.value.toUpperCase()"
                  @keyup.enter="nextFocus($refs.refHoraTope[0], 'input', 100)"
                  autocomplete="off" />
              </div>
            </div>
            <div class=" form-div col-md-12 col-sm-12 col-xs-12 p-0"> 
               <div class="col-md-12 separate-in-form">
                 <label class="control-label label-sm">Horas Riesgo</label>
               </div>
            </div>
            <div class="form-div col-md-12 col-sm-12 col-xs-12 p-0" style="margin-bottom: 10px;"> 
              <div class=" col-md-12 col-sm-12 col-xs-12  p-l-0 p-r-0 m-b-10 paginate-padding" style="overflow-x: auto" >
                  <table class="  table table-responsive table-hover table-bordered  custom-table" >
                    <thead>
                      <tr>
                        <th>RIESGO</th>
                        <th>HORA INICIAL</th>
                        <th>HORA TOPE</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, key) in list.Popup.TablaDetalle" :key="key + 1">
                        <td class="text-left">{{ item.Riesgo }}</td>
                        <td class="text-center" style="width: 25% !important; text-align: center;">
                          <div>
                            <input  v-model="item.HoraInicial"
                                    class="form-control input-sm"
                                    ref="refHoraInicial"
                                    autocomplete="off"
                                    style="text-align: center;"
                                    disabled/>
                          </div>
                        </td>
                        <td class="text-center" style="width: 25% !important; text-align: center;">
                          <div v-show="key < 3">
                            <input  v-model="item.HoraTope"
                                    class="form-control input-sm"
                                    v-mask="'##:##'"
                                    maxlength="5"
                                    ref="refHoraTope"
                                    autocomplete="off"
                                    @keyup.enter="nextFocus($refs.refHoraTope[key+1], 'input', 100)"
                                    style="text-align: center;"/>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
               </div>
            </div>
            <div class="form-div col-md-12 col-sm-12 col-xs-12 p-0" style="margin-bottom: 10px;"> 
              <div class="col-md-12 separate-in-form">
                 <label class="control-label label-sm">Aplica a</label>
               </div>
            </div>
            <div class="form-div col-md-12 col-sm-12 col-xs-12 p-0" style="margin-bottom: 10px;"> 
              <div class=" col-md-12 col-sm-12 col-xs-12  p-l-0 p-r-0 m-b-10 paginate-padding" style="overflow-x: auto" >
                  <table class="  table table-responsive table-hover table-bordered  custom-table" >
                    <thead>
                      <tr>
                        <th>OPERACIÓN</th>
                        <th>SELECCIÓN</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, key) in list.Popup.TablaDetalle2" :key="key + 1">
                        <td class="text-left">{{ item.Descripcion }}</td>
                        <td class="text-center" style="width: 25% !important; text-align: center;">
                          <div>
                            <input  v-model="item.Selected"
                                    type="checkbox"
                                    name="IdCheckSelec"
                                    id="IdCheckSelec"/>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
               </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button @click="cleanField()" type="button" class="btn btn-danger" id="closeModalRegH" data-bs-dismiss="modal">
            <i class="fa fa-close" style="margin-right: 5px"></i>Close
          </button>
          <button @click="register()" type="button" class="btn btn-primary" ref="btnGrabar1">
            <i class="fa fa-save" style="margin-right: 5px"></i>Grabar
          </button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import _ from 'lodash';
import { APP } from "../../Content/base";


 export default {
        name:"appVueConfigHoraSueño",
        el: "#SoatSeguroVehicular",
  data() {
    return {
      filtro: {
        Descripcion: "",
        Popup: {
          IdSleepTime:0,
          Codigo:'',
          Descripcion:'',
        },
      },
      list: {
        Operacion: [],
        Popup: {
          TablaDetalle:[ 
            {Riesgo: 'CRITICO', HoraInicial: '00:00', HoraTope:'00:00'},
            {Riesgo: 'ALTO', HoraInicial: '00:00', HoraTope:'00:00'},
            {Riesgo: 'MODERADO', HoraInicial: '00:00', HoraTope:'00:00'},
            {Riesgo: 'BAJO', HoraInicial: '00:00', HoraTope:'00:00'},
          ],
          TablaDetalle2:[],
        },

        TablaDetalle: [],
      },
      vselect: {
        Operacion: null,
        Popup: {
          
        },
      },
      paginate: ["items"],
      per: 10,
      NUM_RESULTS: 10, // Numero de resultados por página
      pag: 1, // Página inicial
      vshowSoat: true,
    };
  },
  async mounted() {
    let _this = this;
    
     await _this.getOperacion();
     await _this.geBusqueda();
  },
  components: {},
  methods: {
    nextFocus: async function (ref, type, time = 100) {
      setTimeout(() => {
        switch (type) {
          case "input":
            ref.focus();
            break;
          case "money":
            ref.$refs.money.focus();
            break;
          case "radio":
            ref.$refs.input.focus();
            break;
          case "check":
            ref.$refs.input.focus();
            break;
          case "combo":
            ref.$refs.search.focus();
            break;
          case "btn":
            ref.focus();
            break;
          default:
            ref();
        }
      }, time);
    },
    getOperacion: async function () {
      let _this = this;

      this.axios.defaults.headers.common["Authorization"] = "Bearer " + JSON.parse(localStorage.getItem("SessionToken"));

      await this.axios
        .get("api/netcore/OperationHseList")
        .then((res) => {
          if (res.data.EsCorrecto) {

            _this.list.Operacion = res.data.Valor;
            _this.list.Popup.TablaDetalle2 = _.cloneDeep(res.data.Valor).map(x => { return { ...x, Selected: false }; });

          } else {
            APP.msg.error(this.$toast, res.data.Mensaje);
          }
        })
        .catch((error) => {
          APP.msg.error(
            this.$toast,
            "Token expirado Inicie session..!!" + error
          );
          localStorage.clear();
          this.$store.state.isLogeado = false;
          this.$router.push("/");
        });
    },
    geBusqueda: async function () {
      let _this = this;

      this.axios.defaults.headers.common["Authorization"] = "Bearer " + JSON.parse(localStorage.getItem("SessionToken"));

      var request = {
        "Descripcion": _this.filtro.Descripcion || "",
        "IdOperationHSE": _this.vselect.Operacion ? parseInt(_this.vselect.Operacion.Id) : 0
      };

      await this.axios
        .post("api/configHoraSueño/HSESleepTimeList", request)
        .then((res) => {
          if (res.data.EsCorrecto) {

            _this.list.TablaDetalle = res.data.Valor;

            if( _this.list.TablaDetalle.length >0){
              _this.pag = 1
            }
           
          } else {
            APP.msg.error(this.$toast, res.data.Mensaje);
          }
        })
        .catch((error) => {
          APP.msg.error(
            this.$toast,
            "Token expirado Inicie session..!!" + error
          );
          localStorage.clear();
          this.$store.state.isLogeado = false;
          this.$router.push("/");
        });
    },
    TablePopupDetalle: async function(){
      let _this = this;
      
      if(_this.list.Popup.TablaDetalle.length > 0){

        _this.list.Popup.TablaDetalle[1].HoraInicial = _this.list.Popup.TablaDetalle[0].HoraTope;
        _this.list.Popup.TablaDetalle[2].HoraInicial = _this.list.Popup.TablaDetalle[1].HoraTope;
        _this.list.Popup.TablaDetalle[3].HoraInicial = _this.list.Popup.TablaDetalle[2].HoraTope;
      }

    },
    register: async function () {
      let _this = this;

      var validar = await _this.validarCampos();
      if(validar){
          this.axios.defaults.headers.common["Authorization"] = "Bearer " + JSON.parse(localStorage.getItem("SessionToken"));

          var usuario = JSON.parse(localStorage.getItem("Usuario"));

          var request = {
            "IdSleepTime":_this.filtro.Popup.IdSleepTime > 0 ? parseInt( _this.filtro.Popup.IdSleepTime) : 0,
            "Code": _this.filtro.Popup.Codigo,
            "Name": _this.filtro.Popup.Descripcion,
            "Low": _this.list.Popup.TablaDetalle[3].HoraInicial,
            "Modest": _this.list.Popup.TablaDetalle[2].HoraTope,
            "Hight": _this.list.Popup.TablaDetalle[1].HoraTope,
            "Critical": _this.list.Popup.TablaDetalle[0].HoraTope,
            "Active": true,
            "UsrRegister": usuario ? parseInt(usuario.UsuarioId) : 0,
            "Type": _this.filtro.Popup.IdSleepTime > 0 ? '2' : '1',
          };

          var Data = _.filter(_this.list.Popup.TablaDetalle2, ['Selected', true]);
          request.Detalle = [];
          Data.forEach((_item) => {
                request.Detalle.push({
                  "IdOperationHSE": parseInt(_item.Id)
                });
          });

          await this.axios
            .post("api/configHoraSueño/registerUpdate", request)
            .then(async (res) => {
              if (res.data.EsCorrecto) {
              
                APP.msg.success(this.$toast, "Se registro correctamente ..!");
                document.getElementById('closeModalRegH').click();
                _this.cleanField();
                await _this.geBusqueda();
              } else {
                APP.msg.error(this.$toast, res.data.Mensaje);
              }
            })
            .catch((error) => {
              APP.msg.error(
                this.$toast,
                "Token expirado Inicie session..!!" + error
              );
              localStorage.clear();
              this.$store.state.isLogeado = false;
              this.$router.push("/");
            });
      }
    },
    cleanField: function(){
        let _this = this;

           _this.list.Popup.TablaDetalle = [];
           _this.filtro.Popup.IdSleepTime= 0;
           _this.filtro.Popup.Codigo= '';
           _this.filtro.Popup.Descripcion= '';
           _this.list.Popup.TablaDetalle = [ 
            {Riesgo: 'CRITICO', HoraInicial: '00:00', HoraTope:'00:00'},
            {Riesgo: 'ALTO', HoraInicial: '00:00', HoraTope:'00:00'},
            {Riesgo: 'MODERADO', HoraInicial: '00:00', HoraTope:'00:00'},
            {Riesgo: 'BAJO', HoraInicial: '00:00', HoraTope:'00:00'},
          ];
          _this.list.Popup.TablaDetalle2.forEach((_item) => { _item.Selected = false;});

    },
    Delete: async function (_item) {
    
        if(await APP.msg.confirm(this.$swal.fire, "Eliminar", "se eliminara ¿Desea continuar?")){

          this.axios.defaults.headers.common["Authorization"] = "Bearer " + JSON.parse(localStorage.getItem("SessionToken"));

          await this.axios
            .get("api/configHoraSueño/delete/" + (_item.IdSleepTime > 0 ? String(_item.IdSleepTime) : ''))
            .then(async (res) => {
              if (res.data.EsCorrecto) {

                APP.msg.success(this.$toast, "Se elimino correctamente ..!");
                await this.geBusqueda();

              } else {
                APP.msg.error(this.$toast, res.data.Mensaje);
              }
            })
            .catch((error) => {
              APP.msg.error(
                this.$toast,
                "Token expirado Inicie session..!!" + error
              );
              localStorage.clear();
              this.$store.state.isLogeado = false;
              this.$router.push("/");
            });
      }

    },
    validarCampos: async function(){
                let _this = this;
                var response = true;

                if( _this.filtro.Popup.Codigo === ''){
                  APP.msg.warning( this.$toast,"Ingrese Codigo");
                  _this.nextFocus(this.$refs.refCodigo, 'input', 200);
                  response = false;
                  return response;
                }

                if( _this.filtro.Popup.Descripcion === ''){
                  APP.msg.warning( this.$toast,"Ingrese Descripcion");
                  _this.nextFocus(this.$refs.refDescripcion, 'input', 200);
                  response = false;
                  return response;
                }
                return response;
    },

    openModal: async function (_item) {
      let _this = this;
      setTimeout(async () => {
        _this.cleanField();
        _this.filtro.Popup.IdSleepTime = _item.IdSleepTime;

        if(_item.IdSleepTime > 0){
          
          await _this.GetOpcionesEdit(_item);

          _this.filtro.Popup.Codigo= _item.Code;
          _this.filtro.Popup.Descripcion= _item.Name;

          _this.list.Popup.TablaDetalle[0].HoraTope = _item.Critical;
          _this.list.Popup.TablaDetalle[1].HoraTope = _item.Hight;
          _this.list.Popup.TablaDetalle[2].HoraTope = _item.Modest;
          _this.list.Popup.TablaDetalle[3].HoraInicial = _item.Low;

          this.nextFocus(this.$refs.refDescripcionAnx, 'input', 200);
        }

      }, 300);
      
    },
    GetOpcionesEdit: async function (_item) {
      let _this = this;

      this.axios.defaults.headers.common["Authorization"] = "Bearer " + JSON.parse(localStorage.getItem("SessionToken"));

      await this.axios
        .get("api/configHoraSueño/SleepTimeAnxSelect/" + (_item.IdSleepTime > 0 ? String(_item.IdSleepTime) : ''))
        .then(async (res) => {
          if (res.data.EsCorrecto) {

            var _Data = _.cloneDeep(res.data.Valor);

            if(_Data.length > 0){
              for (var i = 0; i < _Data.length; i++) {
                for (var x = 0; x < _this.list.Popup.TablaDetalle2.length; x++) {
                 
                  if( parseInt(_this.list.Popup.TablaDetalle2[x].Id) === parseInt(_Data[i].IdOperationHSE)){
                    _this.list.Popup.TablaDetalle2[x].Selected = true;
                  }
                
                }
              }
            }

          } else {
            APP.msg.error(this.$toast, res.data.Mensaje);
          }
        })
        .catch((error) => {
          APP.msg.error(
            this.$toast,
            "Token expirado Inicie session..!!" + error
          );
          localStorage.clear();
          this.$store.state.isLogeado = false;
          this.$router.push("/");
        });

    },
    Nuevo: async function(){
      setTimeout(()=>{
        this.nextFocus(this.$refs.refCodigo, 'input', 200);
      },300);
    },
   
  },
  watch: {
    'list.Popup.TablaDetalle': {
            handler: function () {
                let _this = this;

               _this.TablePopupDetalle(); 
            },
            deep: true
     },
     'filtro.Descripcion': function(newVal){
        if(newVal.length > 3){
          this.geBusqueda();
        }
     },
     'vselect.Operacion': function(){
      this.geBusqueda();
     }
  },
}
</script>

<style lang="scss" scoped>

</style>