import { createRouter, createWebHistory } from "vue-router";
import store from "../store";

import Dashboard from "../views/Dashboard.vue";
import Login from "../views/Login.vue";
import appVueControlHseViajeProg from "../views/ControlHseViajeProg/Form.vue";
import appVueConfigHoraSueño from "../views/ConfigHoraSueño/Form.vue";
import appVueRegistrosDiarios from "../views/RegistrosDiarios/Form.vue";
import appVueControlesDiarios from "../views/ControlesDiarios/Form.vue";
import appVueControlHoraSuenio from "../views/ControlHoraSu/Form.vue";
import appVueRegistroActosRacs from "../views/RegistroActosCondiRACS/Form.vue";

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/dashboard",
  },
  {
    path: "/login",
    name: "Login",
    component: Login
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard
  },
  {
    path: "/controlHseViajeProg",
    name: "ControlHseViajeProg",
    component: appVueControlHseViajeProg
  },
  {
    path: "/configHoraSue",
    name: "ConfigHoraSuenio",
    component: appVueConfigHoraSueño
  },
  {
    path: "/registrosDiarios",
    name: "RegistrosDiarios",
    component: appVueRegistrosDiarios
  },
  {
    path: "/controlesDiarios",
    name: "ControlesDiarios",
    component: appVueControlesDiarios
  },
  {
    path: "/controlHoraSu",
    name: "ControlesHoraSu",
    component: appVueControlHoraSuenio
  },
  {
    path: "/registroActosCondi",
    name: "RegistroActosCondi",
    component: appVueRegistroActosRacs
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

router.beforeEach((to, from, next) => {
  const isLogged = JSON.parse(localStorage.getItem("SessionToken"));
  if (to.name !== 'Login' && !isLogged && !store.state.isLogeado) {
    localStorage.clear();
    next({ name: 'Login' });
  }else {
    next()
  }
    
})


export default router;