<template>
    <div class="Container">
    <div style="margin-bottom: 5px !important">
      <button @click="Nuevo()" class="btn btn-success" style="margin-right: 3px !important" data-bs-toggle="modal" data-bs-target="#exampleModal">
        <span style="margin-right: 5px"><i class="fa fa-arrow-right"></i></span>Nuevo
      </button>
      <button @click="geBusqueda()" class="btn btn-primary" style="margin-right: 3px !important">
        <span style="margin-right: 5px"><i class="fa fa-search"></i></span>Buscar
      </button>
    </div>
    <div class="White-Box">
      <div class="form-div" style="margin-bottom: 10px">
        <div class="col-md-9 col-sm-9 col-xs-12 separate-in-form">
          <label class="control-label label-sm">Buscar por Descripción</label>
          <div class="">
            <input
              v-model="filtro.Descripcion"
              name="Descripcion"
              class="form-control input-sm"
              placeholder="Ingrese Descripción"
              @input="filtro.Descripcion = $event.target.value.toUpperCase()"
              ref="refDescripcion"
              autocomplete="off"
            />
          </div>
        </div>
        <div class="col-md-3 col-sm-3 col-xs-12 separate-in-form">
          <label class="control-label label-sm">Tipo Operación</label>
          <div class="">
            <v-select
              v-model="vselect.Operacion"
              name="Operacion"
              :clearable="true"
              class="v-select-form"
              label="Descripcion"
              placeholder="--Seleccione--"
              :options="list.Operacion"
              ref="refOperacion">
            </v-select>
          </div>
        </div>
        <!-- <div class="col-md-3 col-sm-3 col-xs-12 separate-in-form">
          <label class="control-label label-sm">&nbsp;</label>
          <div class="">
            <input
              v-model="filtro.SoloActivo"
              name=""
              id="IdSoloActivo"
              type="checkbox"
              ref="refSoloActivo"
              style="margin-right: 5px;"/>
              <label for="IdSoloActivo" class="control-label label-sm">Solo Activo</label>
          </div>
        </div> -->


      </div>
      <div class="row">
        <div
          class=" col-md-12 col-sm-12 col-xs-12  p-l-0 p-r-0 m-b-10 paginate-padding" style="overflow-x: auto" >
          <table class="  table table-responsive table-hover table-bordered  custom-table" >
            <thead>
              <tr>
                <th>CODIGO</th>
                <th>REGISTRO</th>
                <th>FRECUENCIA</th>
                <th>TIPO OPERACION</th>
                <th>CONDICIONAL</th>
                <!-- <th>ESTADO</th> -->
                <th>ACCIONES</th>
              </tr>
            </thead>
            <tbody>
              <tr 
               v-for="(item, key) in list.TablaDetalle" :key="key + 1"
               v-show="(pag - 1) * NUM_RESULTS <= key && pag * NUM_RESULTS > key">
                <td class="text-left">{{ item.Code }}</td>
                <td class="text-left">{{ item.Name }}</td>
                <td class="text-center">
                  <template v-if=" item.StarDay">
                         SI
                  </template>
                  <template v-else>
                         NO
                  </template>
                </td>
                <td class="text-left">{{ item.Operacion }}</td>
                <td class="text-center">
                  <template v-if=" item.Conditional">
                         SI
                  </template>
                  <template v-else>
                         NO
                  </template>
                </td>
                <td class="text-center" style="text-align: center">
                    <a href="javascript:void(0)"
                      title="Ver Documento"
                      class="text-primary m-r-10"
                      id="Info"
                      style="color: #178719; margin-right: 5px !important"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                      @click="openModal(item)">
                      <span class="fa fa-edit" style="font-size: 1.6em" ></span>
                    </a>
                    <a href="javascript:void(0)"
                      title="Ver Documento"
                      class="text-danger m-r-10"
                      id="Info"
                      style="color: #178719"
                      @click="Delete(item)" >
                      <span class="fa fa-trash-o" style="font-size: 1.6em" ></span>
                    </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          class="col-md-12 col-sm-12 col-xs-12 p-0"
          style="margin-top: 10px !important"
        >
          <nav aria-label="Page navigation" class="text-center">
            <ul class="pagination text-center pull-right">
              <li>
                <a
                  href="#" aria-label="Previous" v-show="pag != 1"
                  @click.prevent="pag -= 1" style="padding: 0px 0px 0px 0px" >
                  <span aria-hidden="true">
                    <button class="btn btn-info" style="height: 25px; line-height: 10px; color: #344767">
                      <i class="fa fa-angle-double-left"></i>
                    </button>
                  </span>
                </a>
              </li>
              <li>
                <a
                  href="#" aria-label="Next" v-show="(pag * NUM_RESULTS) / list.TablaDetalle.length < 1"
                  @click.prevent="pag += 1" style="padding: 0px 10px 0px 5px" >
                  <span aria-hidden="true">
                    <button class="btn btn-info" style="height: 25px; line-height: 10px; color: #344767" >
                      <i class="fa fa-angle-double-right"></i>
                    </button>
                  </span>
                </a>
              </li>
            </ul>
          </nav>
        </div>

      </div>
    </div>
  </div>

<!-- Modal -->
  <div
    class="modal fade"
    id="exampleModal"
    tabindex="-1"
    data-bs-backdrop="static"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            Nuevo Registro
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="form-div col-md-12 col-sm-12 col-xs-12 p-0">
            <div class="col-md-3 col-sm-3 col-xs-12 separate-in-form">
              <label class="control-label label-sm">Codigo</label>
              <div class="">
                <input
                  v-model="filtro.Popup.Codigo"
                  name="Codigo"
                  class="form-control input-sm"
                  placeholder="0000"
                  ref="refCodigoRD"
                  @input="filtro.Popup.Codigo = $event.target.value.toUpperCase()"
                  @keyup.enter="nextFocus($refs.refDescripcionRD, 'input', 100)"
                  autocomplete="off"
                  maxlength="4" />
              </div>
            </div>
            <div class="col-md-9 col-sm-9 col-xs-12 separate-in-form">
              <label class="control-label label-sm">Descripción</label>
              <div class="">
                <input
                  v-model="filtro.Popup.Descripcion"
                  name="Descripcion"
                  class="form-control input-sm"
                  placeholder=""
                  @input="filtro.Popup.Descripcion = $event.target.value.toUpperCase()"
                  ref="refDescripcionRD"
                  autocomplete="off" />
              </div>
            </div>
          </div>
          <div class="form-div col-md-12 col-sm-12 col-xs-12 p-0">
            <div class="col-md-7 col-sm-7 col-xs-12 separate-in-form">
              <label class="control-label label-sm">Frecuencia</label>
              <div class="form-div col-md-12 col-sm-12 col-xs-12 p-0">
                <form class="form-div col-md-12 col-sm-12 col-xs-12 p-0">
                    <div class="col-md-6 col-sm-6 col-xs-12 p-0">
                        <input v-model="filtro.Popup.Frecuencia" id="IdInicioJornada" type="radio" value="1"/>
                        <label for="IdInicioJornada" class="control-label label-sm">&nbsp;&nbsp;Inicio Jornada</label>
                    </div>
                    <div class="col-md-6 col-sm-6 col-xs-12 p-0">
                        <input v-model="filtro.Popup.Frecuencia" id="IdDuranteJornada" type="radio" value="2"/>
                        <label for="IdDuranteJornada" class="control-label label-sm">&nbsp;&nbsp;Durante Jornada</label>
                    </div>
               </form>
              </div>
            </div>
            <div class="col-md-5 col-sm-5 col-xs-12 separate-in-form">
              <label class="control-label label-sm">Condicional para iniciar viaje</label>
              <div class="form-div col-md-12 col-sm-12 col-xs-12 p-0">
                <form class="form-div col-md-12 col-sm-12 col-xs-12 p-0">
                <div class="col-md-6 col-sm-6 col-xs-12 p-0">
                    <input v-model="filtro.Popup.CondicionalIniciarViaje" id="IdSI" type="radio" value="1"/>
                    <label for="IdSI" class="control-label label-sm">&nbsp;&nbsp;SI</label>
                </div>
                <div class="col-md-6 col-sm-6 col-xs-12 p-0">
                    <input v-model="filtro.Popup.CondicionalIniciarViaje" id="IdNO" type="radio" value="2"/>
                    <label for="IdNO" class="control-label label-sm">&nbsp;&nbsp;NO</label>
                </div>
            </form>

              </div>
            </div>
          </div>
          <div class="form-div col-md-12 col-sm-12 col-xs-12 p-0">
            <div class="col-md-6 col-sm-6 col-xs-12 separate-in-form">
                    <input v-model="filtro.Popup.SolicitarAddImagenObl" id="IdSolicitarimagenadd" type="checkbox"/>
                    <label for="IdSolicitarimagenadd" class="control-label label-sm">&nbsp;&nbsp;Solicitar adjunto imagen obligatorio</label>
            </div>
            <div class="col-md-6 col-sm-6 col-xs-12 separate-in-form">
                    <input v-model="filtro.Popup.IngresaObsObl" id="IdIngresaImagenObl" type="checkbox"/>
                    <label for="IdIngresaImagenObl" class="control-label label-sm">&nbsp;&nbsp;Ingresar observacion obligatorio</label>
            </div>
            
          </div>
          <div class=" col-md-12 col-sm-12 col-xs-12 p-l-0 p-r-0 m-t-10 m-b-10 paginate-padding " style="overflow-x: auto; margin-top:10px">
          <table class=" table table-responsive table-hover table-bordered custom-table ">
            <thead>
              <tr>
                <th>OPERACIÓN</th>
                <th>SELECCIÓN</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, key) in list.Popup.TablaDetalle2" :key="key + 1">
                <td class="text-left">{{ item.Descripcion }}</td>
                <td class="text-center" style="width: 25% !important; text-align: center;">
                  <div>
                    <input  v-model="item.Selected"
                            type="checkbox"
                            name="IdCheckSelec"
                            id="IdCheckSelec"/>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        </div>
        <div class="modal-footer">
          <button @click="cleanField()" type="button" class="btn btn-danger" id="closeModalRegDia" data-bs-dismiss="modal">
            <i class="fa fa-close" style="margin-right: 5px"></i>Close
          </button>
          <button @click="register()" type="button" class="btn btn-primary" ref="btnGrabar1">
            <i class="fa fa-save" style="margin-right: 5px"></i>Grabar
          </button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import _ from 'lodash';
import { APP } from "../../Content/base";

export default {
  name:"appVueRegistrosDiarios",
  el: "#appVueRegistrosDiarios",
  data() {
    return {
      filtro: {
        Descripcion: "",
        Popup: {
          IdDailyCheck:0,
          Codigo:'',
          Descripcion:'',
          Frecuencia: '1',
          CondicionalIniciarViaje:'1',
          SolicitarAddImagenObl: true,
          IngresaObsObl: true,
        },
      },
      list: {
        Operacion:[],
        Popup: {
          TablaDetalle2:[],
        },

        TablaDetalle: [],
      },
      vselect: {
        Operacion: null,
        Popup: {
          
        },
      },
      paginate: ["items"],
      per: 10,
      NUM_RESULTS: 10, // Numero de resultados por página
      pag: 1, // Página inicial
      vshowSoat: true,
    };
  },
  async mounted() {
    let _this = this;
     await _this.getOperacion();
     await _this.geBusqueda();
  },
  components: {},
  methods: {
    nextFocus: async function (ref, type, time = 100) {
      setTimeout(() => {
        switch (type) {
          case "input":
            ref.focus();
            break;
          case "money":
            ref.$refs.money.focus();
            break;
          case "radio":
            ref.$refs.input.focus();
            break;
          case "check":
            ref.$refs.input.focus();
            break;
          case "combo":
            ref.$refs.search.focus();
            break;
          case "btn":
            ref.focus();
            break;
          default:
            ref();
        }
      }, time);
    },
    getOperacion: async function () {
      let _this = this;

      this.axios.defaults.headers.common["Authorization"] = "Bearer " + JSON.parse(localStorage.getItem("SessionToken"));

      await this.axios
        .get("api/netcore/OperationHseList")
        .then((res) => {
          if (res.data.EsCorrecto) {

            _this.list.Operacion = res.data.Valor;
            _this.list.Popup.TablaDetalle2 = _.cloneDeep(res.data.Valor).map(x => { return { ...x, Selected: false }; });

          } else {
            APP.msg.error(this.$toast, res.data.Mensaje);
          }
        })
        .catch((error) => {
          APP.msg.error(
            this.$toast,
            "Token expirado Inicie session..!!" + error
          );
          localStorage.clear();
          this.$store.state.isLogeado = false;
          this.$router.push("/");
        });
    },
    geBusqueda: async function () {
      let _this = this;

      this.axios.defaults.headers.common["Authorization"] = "Bearer " + JSON.parse(localStorage.getItem("SessionToken"));

      var request = {
        "Descripcion": _this.filtro.Descripcion || "",
        "IdOperationHSE": _this.vselect.Operacion ? parseInt(_this.vselect.Operacion.Id) : 0
      };

      await this.axios
        .post("api/registrosDiario/HSEDailyCheckList", request)
        .then((res) => {
          if (res.data.EsCorrecto) {

            _this.list.TablaDetalle = res.data.Valor;

            if( _this.list.TablaDetalle.length >0){
              _this.pag = 1
            }
           
          } else {
            APP.msg.error(this.$toast, res.data.Mensaje);
          }
        })
        .catch((error) => {
          APP.msg.error(
            this.$toast,
            "Token expirado Inicie session..!!" + error
          );
          localStorage.clear();
          this.$store.state.isLogeado = false;
          this.$router.push("/");
        });
    },
    Delete: async function (_item) {
        
        if(await APP.msg.confirm(this.$swal.fire, "Eliminar", "se eliminara ¿Desea continuar?")){

          this.axios.defaults.headers.common["Authorization"] = "Bearer " + JSON.parse(localStorage.getItem("SessionToken"));

          await this.axios
            .get("api/registrosDiario/delete/" + (_item.IdDailyCheck > 0 ? String(_item.IdDailyCheck) : ''))
            .then(async (res) => {
              if (res.data.EsCorrecto) {

                APP.msg.success(this.$toast, "Se elimino correctamente ..!");
                await this.geBusqueda();

              } else {
                APP.msg.error(this.$toast, res.data.Mensaje);
              }
            })
            .catch((error) => {
              APP.msg.error(
                this.$toast,
                "Token expirado Inicie session..!!" + error
              );
              localStorage.clear();
              this.$store.state.isLogeado = false;
              this.$router.push("/");
            });
      }

    },
    register: async function () {
      let _this = this;

      var validar = await _this.validarCampos();
      if(validar){
          this.axios.defaults.headers.common["Authorization"] = "Bearer " + JSON.parse(localStorage.getItem("SessionToken"));

          var usuario = JSON.parse(localStorage.getItem("Usuario"));

          var request = {
            "IdDailyCheck": _this.filtro.Popup.IdDailyCheck > 0 ? parseInt( _this.filtro.Popup.IdDailyCheck) : 0,
            "Code":  _this.filtro.Popup.Codigo,
            "Name": _this.filtro.Popup.Descripcion,
            "StarDay": _this.filtro.Popup.Frecuencia == '1' ? true : false,
            "Conditional":  _this.filtro.Popup.CondicionalIniciarViaje == '1' ? true : false,
            "ImageAttach": _this.filtro.Popup.SolicitarAddImagenObl,
            "DescriptionAttach":  _this.filtro.Popup.IngresaObsObl,
            "Active": true,
            "UsrRegister": usuario ? parseInt(usuario.UsuarioId) : 0,
            "Type": _this.filtro.Popup.IdDailyCheck > 0 ? '2' : '1'
          };

          var Data = _.filter(_this.list.Popup.TablaDetalle2, ['Selected', true]);
          request.Detalle = [];
          Data.forEach((_item) => {
                request.Detalle.push({
                  "IdOperationHSE": parseInt(_item.Id)
                });
          });

          await this.axios
            .post("api/registrosDiario/registerUpdate", request)
            .then(async (res) => {
              if (res.data.EsCorrecto) {
              
                APP.msg.success(this.$toast, "Se registro correctamente ..!");
                document.getElementById('closeModalRegDia').click();
                _this.cleanField();

                await _this.geBusqueda();
              } else {
                APP.msg.error(this.$toast, res.data.Mensaje);
              }
            })
            .catch((error) => {
              APP.msg.error(
                this.$toast,
                "Token expirado Inicie session..!!" + error
              );
              localStorage.clear();
              this.$store.state.isLogeado = false;
              this.$router.push("/");
            });
      }
    },
    validarCampos: async function(){
                let _this = this;
                var response = true;

                if( _this.filtro.Popup.Codigo === ''){
                  APP.msg.warning( this.$toast,"Ingrese Codigo");
                  _this.nextFocus(this.$refs.refCodigoRD, 'input', 200);
                  response = false;
                  return response;
                }

                if( _this.filtro.Popup.Descripcion === ''){
                  APP.msg.warning( this.$toast,"Ingrese Descripcion");
                  _this.nextFocus(this.$refs.refDescripcionRD, 'input', 200);
                  response = false;
                  return response;
                }
                return response;
    },
    cleanField: function(){
        let _this = this;

           _this.filtro.Popup.IdDailyCheck= 0;
           _this.filtro.Popup.Codigo= '';
           _this.filtro.Popup.Descripcion= '';
           _this.filtro.Popup.Frecuencia= '1';
           _this.filtro.Popup.CondicionalIniciarViaje = '1';
           _this.filtro.Popup.SolicitarAddImagenObl = true;
           _this.filtro.Popup.IngresaObsObl = true;
          
          _this.list.Popup.TablaDetalle2.forEach((_item) => { _item.Selected = false;});

    },

    openModal: async function (_item) {
      let _this = this;
      setTimeout(async () => {
        _this.cleanField();
        _this.filtro.Popup.IdDailyCheck = _item.IdDailyCheck;

        if(_item.IdDailyCheck > 0){
          
          await _this.GetOpcionesEdit(_item);

          _this.filtro.Popup.Codigo = _item.Code;
          _this.filtro.Popup.Descripcion = _item.Name;
          _this.filtro.Popup.Frecuencia = _item.StarDay ? '1' : '2';
          _this.filtro.Popup.CondicionalIniciarViaje = _item.Conditional ? '1' : '2';
          _this.filtro.Popup.SolicitarAddImagenObl = _item.ImageAttach;
          _this.filtro.Popup.IngresaObsObl = _item.DescriptionAttach;

          this.nextFocus(this.$refs.refDescripcionRD, 'input', 200);
        }

      }, 300);
      
    },
    GetOpcionesEdit: async function (_item) {
      let _this = this;

      this.axios.defaults.headers.common["Authorization"] = "Bearer " + JSON.parse(localStorage.getItem("SessionToken"));

      await this.axios
        .get("api/registrosDiario/DailyCheckAnxSelect/" + (_item.IdDailyCheck > 0 ? String(_item.IdDailyCheck) : ''))
        .then(async (res) => {
          if (res.data.EsCorrecto) {

            var _Data = _.cloneDeep(res.data.Valor);

            if(_Data.length > 0){
              for (var i = 0; i < _Data.length; i++) {
                for (var x = 0; x < _this.list.Popup.TablaDetalle2.length; x++) {
                 
                  if( parseInt(_this.list.Popup.TablaDetalle2[x].Id) === parseInt(_Data[i].IdOperationHSE)){
                    _this.list.Popup.TablaDetalle2[x].Selected = true;
                  }
                
                }
              }
            }

          } else {
            APP.msg.error(this.$toast, res.data.Mensaje);
          }
        })
        .catch((error) => {
          APP.msg.error(
            this.$toast,
            "Token expirado Inicie session..!!" + error
          );
          localStorage.clear();
          this.$store.state.isLogeado = false;
          this.$router.push("/");
        });

    },
    Nuevo: async function(){
      setTimeout(()=>{
        this.nextFocus(this.$refs.refCodigoRD, 'input', 200);
      },300);
    },
   

  },
  watch: {
    'filtro.Descripcion': function(newVal){
        if(newVal.length > 3){
          this.geBusqueda();
        }
     },
     'vselect.Operacion': function(){
      this.geBusqueda();
     }
  },
}
</script>

<style scoped>
</style>

