<template>
    <div class="form-div" style="margin-bottom: 10px">
       <div class="col-md-3 col-sm-3 col-xs-3 p-0">
                     <div class="col-md-12 col-sm-12 col-xs-12 p-0" style="margin-top: 10px;padding: 10px !important;margin-bottom: 30px;border: 2px solid rgb(31 70 163);border-radius: 5px !important;">
                        <h1 style="width: 108px;
                                   font-size: 14px;
                                   margin-top: -21px;
                                   margin-left: 7px;
                                   background: white;
                                   font-weight: 700;
                                   margin-bottom: 0px !important;
                                   color: rgb(31 70 163); ">Fechas Jornadas</h1>
                        
                        <div v-for="(item, key) in list.ListFechaComisiones" :key="key + 1" class="col-md-12 col-sm-12 col-xs-12 separate-in-form" style="margin-top: 10px;">
                            <button @click="validarFecha(item, key)" class="btn btn-primary" 
                            style="width: 100%;" :class="{'highlight-row': item.Selected, '': !item.Selected}"
                            :id="'Boton' + String(key)">
                                Dia {{item.numberDay}} - Fecha {{item.date}}
                            </button>
                        </div>
                     </div>
                     <div class="col-md-12 col-sm-12 col-xs-12 p-0" style="padding: 10px !important; margin-bottom: 10px; border: 2px solid rgb(31 70 163); ; border-radius: 5px !important;">
                        <h1 style=" width: 60px; font-size: 14px; margin-top: -21px; margin-left: 7px; background: white; font-weight: 500;margin-bottom: 0px !important; color: rgb(31 70 163);">Resumen</h1>
                        <div class="form-div col-md-12 col-sm-12 col-xs-12 p-0">
                           <div class="col-md-6 col-sm-6 col-xs-12 separate-in-form">
                              <label class="control-label label-sm">NO REGISTRADO</label>
                           </div>
                           <div class="col-md-6 col-sm-6 col-xs-12 separate-in-form">
                            <label class="control-label label-sm">{{filtro.NoEnviado}}</label>
                           </div>
                        </div>
                        <div class="form-div col-md-12 col-sm-12 col-xs-12 p-0">
                           <div class="col-md-6 col-sm-6 col-xs-12 separate-in-form">
                              <label class="control-label label-sm">REGISTRADO</label>
                           </div>
                           <div class="col-md-6 col-sm-6 col-xs-12 separate-in-form">
                            <label class="control-label label-sm">{{filtro.Enviado}}</label>
                           </div>
                        </div>
                        <div class="form-div col-md-12 col-sm-12 col-xs-12 p-0">
                           <div class="col-md-6 col-sm-6 col-xs-12 separate-in-form">
                              <label class="control-label label-sm">AUTORIZADO</label>
                           </div>
                           <div class="col-md-6 col-sm-6 col-xs-12 separate-in-form">
                            <label class="control-label label-sm">{{filtro.Autorizado}}</label>
                           </div>
                        </div>
                        <div class="form-div col-md-12 col-sm-12 col-xs-12 p-0">
                           <div class="col-md-6 col-sm-6 col-xs-12 separate-in-form">
                              <label class="control-label label-sm">OBSERVADO</label>
                           </div>
                           <div class="col-md-6 col-sm-6 col-xs-12 separate-in-form">
                            <label class="control-label label-sm">{{filtro.Observado}}</label>
                           </div>
                        </div>
                   
                    </div>
       </div>
       <div class="offset-md-1 col-md-8 col-sm-8 col-xs-12 p-0">
                        <div class="col-md-12 col-sm-12 col-xs-12 p-0" style="margin-bottom: 30px; margin-top:10px; border: 2px solid rgb(31 70 163); border-radius: 5px !important;">
                            <h1 style=" width: 140px; font-size: 14px; margin-top: -10px; margin-left: 7px; background: white; font-weight: 500;margin-bottom: 0px !important; color: rgb(31 70 163);">Datos Generales Viaje</h1>
                            <div class="form-div col-md-12 col-sm-12 col-xs-12 p-0">
                                <div class="form-div col-md-6 col-sm-6 col-xs-12 p-0">
                                    <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
                                        <label class="control-label label-sm" style="font-weight: 700;">Inicio Ruta:</label>
                                    </div>
                                    <div class="col-md-8 col-sm-8 col-xs-12 separate-in-form">
                                        <label class="control-label label-sm">{{ ItemVista.dateHProgramming }}</label>
                                    </div>
                                </div>
                                <div class="form-div col-md-6 col-sm-6 col-xs-12 p-0">
                                    <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
                                        <label class="control-label label-sm" style="font-weight: 700;">Nro Viaje:</label>
                                    </div>
                                    <div class="col-md-8 col-sm-8 col-xs-12 separate-in-form">
                                        <label class="control-label label-sm">{{ ItemVista.idProgramming }}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="form-div col-md-12 col-sm-12 col-xs-12 p-0">
                                <div class="form-div col-md-6 col-sm-6 col-xs-12 p-0">
                                    <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
                                        <label class="control-label label-sm" style="font-weight: 700;">Operación:</label>
                                    </div>
                                    <div class="col-md-8 col-sm-8 col-xs-12 separate-in-form">
                                        <label class="control-label label-sm">{{ ItemVista.operation }}</label>
                                    </div>
                                </div>
                                <div class="form-div col-md-6 col-sm-6 col-xs-12 p-0">
                                    <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
                                        <label class="control-label label-sm" style="font-weight: 700;">Conductor:</label>
                                    </div>
                                    <div class="col-md-8 col-sm-8 col-xs-12 separate-in-form">
                                        <label class="control-label label-sm">{{ ItemVista.driverPerson1 }}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="form-div col-md-12 col-sm-12 col-xs-12 p-0">
                                <div class="form-div col-md-6 col-sm-6 col-xs-12 p-0">
                                    <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
                                        <label class="control-label label-sm" style="font-weight: 700;">Tracto:</label>
                                    </div>
                                    <div class="col-md-8 col-sm-8 col-xs-12 separate-in-form">
                                        <label class="control-label label-sm">{{ ItemVista.transportUnit1 }}</label>
                                    </div>
                                </div>
                                <div class="form-div col-md-6 col-sm-6 col-xs-12 p-0">
                                    <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
                                        <label class="control-label label-sm" style="font-weight: 700;">Semi Remolque:</label>
                                    </div>
                                    <div class="col-md-8 col-sm-8 col-xs-12 separate-in-form">
                                        <label class="control-label label-sm">{{ ItemVista.transportUnit2 }}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="form-div col-md-12 col-sm-12 col-xs-12 p-0">
                                <div class="form-div col-md-6 col-sm-6 col-xs-12 p-0">
                                    <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
                                        <label class="control-label label-sm" style="font-weight: 700;">Ruta:</label>
                                    </div>
                                    <div class="col-md-8 col-sm-8 col-xs-12 separate-in-form">
                                        <label class="control-label label-sm">{{ ItemVista.route }}</label>
                                    </div>
                                </div>
                                <div class="form-div col-md-6 col-sm-6 col-xs-12 p-0">
                                    <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
                                        <label class="control-label label-sm" style="font-weight: 700;">Guia:</label>
                                    </div>
                                    <div class="col-md-8 col-sm-8 col-xs-12 separate-in-form">
                                        <label class="control-label label-sm"></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 col-sm-12 col-xs-12 p-0" style="margin-top:10px; border: 2px solid rgb(31 70 163); border-radius: 5px !important;">
                            <h1 style=" width: 105px; font-size: 14px; margin-top: -10px; margin-left: 7px; background: white; font-weight: 500;margin-bottom: 0px !important; color: rgb(31 70 163);">Procesos Diarios</h1>
                    
                            <div class="row" style="padding: 10px;">
                                <div class=" col-md-12 col-sm-12 col-xs-12  p-l-0 p-r-0 m-b-10 paginate-padding" style="overflow-x: auto" >
                                    <table class="  table table-responsive table-hover table-bordered  custom-table" >
                                        <thead>
                                            <tr>
                                                <th>PROCESO</th>
                                                <th>ESTADO</th>
                                                <th>HABILITADO</th>
                                                <th>MOTIVO</th>
                                                <th>OBSERVACIONES</th>
                                                <th>FECHA</th>
                                                <th>ACCIONES</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr 
                                            v-for="(item, key) in list.TablaDetalle" :key="key + 1"
                                            v-show="(pag - 1) * NUM_RESULTS <= key && pag * NUM_RESULTS > key">
                                                <td class="text-left">{{ item.nameDailyCheck }}</td>
                                                <td class="text-left">{{ item.dailyState }}</td>
                                                <td class="text-center">
                                                    <div>
                                                        <input type="checkbox" id="checkHabilitate" v-model="item.activate" disabled/>
                                                    </div>
                                                </td>
                                                <td class="text-left">{{ item.motive }}</td>
                                                <td class="text-left">{{ item.observation }}</td>
                                                <td class="text-center">{{ item.date }}</td>
                                                <td class="text-center">
                                                    <template v-if="!item.activate">
                                                        <a  href="javascript:void(0)"
                                                        title="Habilitar"
                                                        class="text-primary m-r-10"
                                                        id="Info"
                                                        style="color: #178719 !important; margin-right: 5px !important"
                                                        @click="Habilitar()">
                                                          <span class="fa fa-check-circle" style="font-size: 1.6em"></span>
                                                        </a>
                                                    </template>
                                                    <template v-else>
                                                        <a  href="javascript:void(0)"
                                                        title="Ver Documento"
                                                        class="text-primary m-r-10"
                                                        id="Info"
                                                        style="color: red !important; margin-right: 5px !important"
                                                        data-bs-toggle="modal" 
                                                        data-bs-target="#exampleModalInhabilitarHoraSue"
                                                        @click="openModal(item)">
                                                          <span class="fa fa-ban" style="font-size: 1.6em"></span>
                                                        </a>
                                                    </template>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="col-md-12 col-sm-12 col-xs-12 p-0" style="margin-top: 10px !important" >
                                    <nav aria-label="Page navigation" class="text-center">
                                        <ul class="pagination text-center pull-right">
                                            <li>
                                                <a href="#" aria-label="Previous" v-show="pag != 1" @click.prevent="pag -= 1" style="padding: 0px 0px 0px 0px" >
                                                    <span aria-hidden="true">
                                                        <button class="btn btn-info" style="height: 25px; line-height: 10px; color: #344767">
                                                        <i class="fa fa-angle-double-left"></i>
                                                        </button>
                                                    </span>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#" aria-label="Next" v-show="(pag * NUM_RESULTS) / list.TablaDetalle.length < 1" @click.prevent="pag += 1" style="padding: 0px 10px 0px 5px" >
                                                    <span aria-hidden="true">
                                                        <button class="btn btn-info" style="height: 25px; line-height: 10px; color: #344767" >
                                                        <i class="fa fa-angle-double-right"></i>
                                                        </button>
                                                    </span>
                                                </a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                    
                        </div>
       </div>
    </div>

    <!-- Modal -->
  <div
    class="modal fade"
    id="exampleModalInhabilitarHoraSue"
    tabindex="-1"
    data-bs-backdrop="static"
    aria-labelledby="exampleModalInhabilitarHoraSueLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalInhabilitarHoraSueLabel">
            INHABILITAR HORAS DE SUEÑO
          </h5>
          <button
            @click="cleanField()"
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="form-div col-md-12 col-sm-12 col-xs-12 p-0">
            <div class="col-md-6 col-sm-6 col-xs-12 separate-in-form">
              <label class="control-label label-sm">Motivo</label>
              <div class="">
                <v-select v-model="vselect.Popup.Motivo"
                          name="Motivo"
                          :clearable="true"
                          class="v-select-form"
                          label="Descripcion"
                          placeholder="--Seleccione--"
                          :options="list.Popup.Motivo"
                          v-on:keydown.enter.prevent="nextFocus($refs.refObservacion, 'input', 200)"
                          ref="refMotivo">
                </v-select>
              </div>
            </div>
            <div class="col-md-6 col-sm-6 col-xs-12 separate-in-form">
              <label class="control-label label-sm">Observación (Cuando es otros)</label>
              <div class="">
                <textarea
                  v-model="filtro.Popup.Observacion"
                  name="Observacion"
                  class="form-control input-sm"
                  placeholder=""
                  ref="refObservacion"
                  @input="filtro.Popup.Descripcion = $event.target.value.toUpperCase()"
                  @keyup.enter="nextFocus($refs.refHoraTope[0], 'input', 100)"
                  rows="2"
                  autocomplete="off">
                  </textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button @click="cleanField()" type="button" class="btn btn-danger" id="closeModalR" data-bs-dismiss="modal">
            <i class="fa fa-close" style="margin-right: 5px"></i>Close
          </button>
          <button @click="geActivateMot()" type="button" class="btn btn-primary" ref="btnGrabar1">
            <i class="fa fa-save" style="margin-right: 5px"></i>Grabar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
 import _ from 'lodash';
 import { APP } from "../../Content/base";
    export default {
        name: "AddControl",
        el: "#AddControl",
        data() {
            return {
            filtro: {
                idProgramming: '',
                fechaSelected: '',
                NoEnviado: 0,
                Enviado: 0,
                Autorizado: 0,
                Observado: 0,
                Popup: {
                    Observacion: '',
                    Item:{},
                },
            },
            list: {
                Popup: {
                    Motivo:[],
                },
                ListFechaComisiones: [],
                TablaDetalleAux: [],
                TablaDetalle: [],
            },
            vselect: {
                Popup: {
                    Motivo:null,
                },
            },
            ItemVista: {},
            paginate: ["items"],
            per: 10,
            NUM_RESULTS: 10, // Numero de resultados por página
            pag: 1, // Página inicial
            key:0,
            };
        },
        async mounted() {
            let _this = this;

            await _this.getMotivo();
            // await _this.getAseguradora();
        },
        components: {},
        methods: {
            nextFocus: async function (ref, type, time = 100) {
            setTimeout(() => {
                switch (type) {
                case "input":
                    ref.focus();
                    break;
                case "money":
                    ref.$refs.money.focus();
                    break;
                case "radio":
                    ref.$refs.input.focus();
                    break;
                case "check":
                    ref.$refs.input.focus();
                    break;
                case "combo":
                    ref.$refs.search.focus();
                    break;
                case "btn":
                    ref.focus();
                    break;
                default:
                    ref();
                }
            }, time);
            },
            getData: async function (_Item) {
                let _this = this;
                
                    //_this.CleanPopUps();
                    this.axios.defaults.headers.common["Authorization"] = "Bearer " + JSON.parse(localStorage.getItem("SessionToken"));

                    _this.filtro.idProgramming = _Item.idProgramming;
                    _this.ItemVista = _Item;
                    await this.axios
                        .get("api/programmingHSE/get/" + (_Item.idProgramming != '' ? String(_Item.idProgramming) : ''))
                        .then((res) => {
                            if (res.data.EsCorrecto) {

                            var _Data = _.cloneDeep(res.data.Valor);

                            if(_Data){
                                _this.list.ListFechaComisiones = _Data.days.map(x => { return { ...x, Selected: false }; });
                                _this.list.TablaDetalleAux = _.cloneDeep(_Data.daily);

                                var newKey = _this.key === 0 ? 0 : _this.key;
                                setTimeout(()=>{document.getElementById('Boton'+ String(newKey)).click();},500)
                                //document.getElementById('Boton0').click();
                            
                            } else {
                                APP.msg.error(this.$toast, res.data.Mensaje);
                            }
                           }
                         })
                        .catch((error) => {
                            APP.msg.error(this.$toast,"Token expirado Inicie session..!!" + error);
                            localStorage.clear();
                            this.$store.state.isLogeado = false;
                            this.$router.push("/");
                        });
            },
            validarFecha: async function(_Item, key){
              let _this = this;
                var _selected = false;
                _this.list.TablaDetalle = [];
                _this.key = key ? key : 0;

                var index = _.findIndex(_this.list.ListFechaComisiones, function (o) { return o.numberDay == _Item.numberDay; });

                _selected = !_this.list.ListFechaComisiones[index].Selected;
                _this.list.ListFechaComisiones.forEach((e) => { e.Selected = false; });
                _this.list.ListFechaComisiones[index].Selected = _selected

             

            if( _this.list.ListFechaComisiones[index].Selected){

                _this.filtro.fechaSelected = _Item.date;
                var data = _.filter(_this.list.TablaDetalleAux, ['date', _this.filtro.fechaSelected]);
                if(data.length > 0){
                    _this.list.TablaDetalle = data;
                }else{
                    _this.list.TablaDetalle = []
                }

            }else{
                 _this.list.TablaDetalle = []
            }

            },
            Habilitar: async function(){
              let _this = this;

              if(await APP.msg.confirm(this.$swal.fire, "HABILITAR","¿Esta seguro(a) de habilitar dicho proceso?")){

                    this.axios.defaults.headers.common["Authorization"] = "Bearer " + JSON.parse(localStorage.getItem("SessionToken"));

                    var request = {
                        "idProgramming": _this.ItemVista ? String(_this.ItemVista.idProgramming) : '',
                        "typeRegister": _this.filtro.Popup.Item.typeRegister || 0,
                        "idDailyCheck": _this.filtro.Popup.Item.idDailyCheck || 0,
                        "date":  _this.filtro.Popup.Item.date || '',
                    };

                    await this.axios
                        .post("api/programmingHSE/dailyCheckActivate", request)
                        .then(async (res) => {
                            if (res.data.EsCorrecto) {
                                await _this.getData(_this.ItemVista);
                                APP.msg.success(this.$toast, "Se registro correctamente...!");
                            } else {
                                APP.msg.error(this.$toast, res.data.Mensaje);
                            }
                        })
                        .catch((error) => {
                            APP.msg.error(this.$toast,"Token expirado Inicie session..!!" + error);
                            localStorage.clear();
                            this.$store.state.isLogeado = false;
                            this.$router.push("/");
                        });
              }

            },
            getMotivo: async function () {
                let _this = this;
            
                this.axios.defaults.headers.common["Authorization"] = "Bearer " + JSON.parse(localStorage.getItem("SessionToken"));

                await this.axios
                    .get("api/netcore/GetMotivo")
                    .then((res) => {
                        if (res.data.EsCorrecto) {

                            _this.list.Popup.Motivo = res.data.Valor;

                        } else {
                            APP.msg.error(this.$toast, res.data.Mensaje);
                        }
                        })
                    .catch((error) => {
                        APP.msg.error(this.$toast,"Token expirado Inicie session..!!" + error);
                        localStorage.clear();
                        this.$store.state.isLogeado = false;
                        this.$router.push("/");
                    });
            },
            openModal: async function(item){
              let _this = this;
              _this.filtro.Popup.Item = item;
              setTimeout(()=>{
                _this.nextFocus(_this.$refs.refMotivo, 'combo', 200);
                },300);
            },
            geActivateMot: async function () {
            let _this = this;

                this.axios.defaults.headers.common["Authorization"] = "Bearer " + JSON.parse(localStorage.getItem("SessionToken"));
                var usuario = JSON.parse(localStorage.getItem("Usuario"));

                var request = {
                    "idProgramming": _this.ItemVista ? String(_this.ItemVista.idProgramming) : '',
                    "typeRegister": _this.filtro.Popup.Item.typeRegister || 0,
                    "idDailyCheck": _this.filtro.Popup.Item.idDailyCheck || 0,
                    "date": _this.filtro.Popup.Item.date || '',
                    "idMotive": _this.vselect.Popup.Motivo ? parseInt(_this.vselect.Popup.Motivo.Id) : 0,
                    "observation": _this.filtro.Popup.Observacion || '',
                    "usrRegister": usuario ? parseInt(usuario.UsuarioId) : 0,
                };

                await this.axios
                    .post("api/programmingHSE/dailyCheckdisable", request)
                    .then(async (res) => {
                        if (res.data.EsCorrecto) {
                        
                        await _this.getData(_this.ItemVista);
                        APP.msg.success(this.$toast, "Se registro correctamente...!");
                        document.getElementById('closeModalR').click();
                        
                        } else {
                            APP.msg.error(this.$toast, res.data.Mensaje);
                        }
                    })
                    .catch((error) => {
                        APP.msg.error(this.$toast,"Token expirado Inicie session..!!" + error);
                        localStorage.clear();
                        this.$store.state.isLogeado = false;
                        this.$router.push("/");
                    });
            },
            cleanField: function(){
               let _this = this;

               _this.filtro.Popup.Item = {};
               _this.vselect.Popup.Motivo = null;
               _this.filtro.Popup.Observacion = '';
               _this.key = 0 ;
            }
        },
        watch: {
            'list.TablaDetalle': {
                handler: function (newVal) {
                    let _this = this;

                    var data = [];
                    data = _.filter(newVal, ['idState', 0]);

                    var data1 = [];
                    data1 = _.filter(newVal, ['idState', 1]);

                    var data2 = [];
                    data2 = _.filter(newVal, ['idState', 2]);

                    var data3 = [];
                    data3 = _.filter(newVal, ['idState', 3]);

                    _this.filtro.NoEnviado = data.length;
                    _this.filtro.Enviado = data1.length;
                    _this.filtro.Autorizado = data2.length;
                    _this.filtro.Observado = data3.length;
                    //_this.filtro.Observado = _.sumBy(newVal, (o) => { return (o.idState === 3) ? parseFloat(o.ValorUnitario * o.CantidadItem || 0) : 0; });
                  
                },
                deep: true
        },

        },
    }
</script>

<style lang="scss" scoped>

</style>