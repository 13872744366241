<template>
     <div class="p-0" v-show="vshowAdd">
        <div class="Container">
                <div style="margin-bottom: 5px !important">
                <!-- <button @click="AddNew()" class="btn btn-success" style="margin-right: 3px !important">
                    <span style="margin-right: 5px"><i class="fa fa-arrow-right"></i></span>Nuevo
                </button> -->
                <button @click="geBusqueda" class="btn btn-primary" style="margin-right: 3px !important">
                    <span style="margin-right: 5px"><i class="fa fa-search"></i></span>Buscar
                </button>
                </div>
                <div class="White-Box">
                    <div class="form-div" style="margin-bottom: 10px">
                        <div class="col-md-2 col-sm-2 col-xs-12 separate-in-form">
                            <label class="control-label label-sm">Desde</label>
                            <div class="">
                                <input  v-model="filtro.Desde"
                                        name="Desde"
                                        class="form-control input-sm"
                                        placeholder="Ingrese Descripción"
                                        ref="refDesde"
                                        type="date"
                                        @keyup.enter="nextFocus($refs.refHasta, 'input', 100)"
                                        autocomplete="off"/>
                            </div>
                        </div>
                        <div class="col-md-2 col-sm-2 col-xs-12 separate-in-form">
                            <label class="control-label label-sm">Hasta</label>
                            <div class="">
                                <input  v-model="filtro.Hasta"
                                        name="Hasta"
                                        class="form-control input-sm"
                                        placeholder="Ingrese Descripción"
                                        ref="refHasta"
                                        type="date"
                                        @keyup.enter="nextFocus($refs.refConductor, 'combo', 200)"
                                        autocomplete="off"/>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
                            <label class="control-label label-sm">Conductor</label>
                            <div class="">
                                <v-select v-model="vselect.Conductor"
                                      name="Conductor"
                                      :clearable="true"
                                      class="v-select-form"
                                      label="Descripcion"
                                      placeholder="--Seleccione--"
                                      :options="list.Conductor"
                                      :filterable="false"
                                      @search="getConductor"
                                      v-on:keydown.enter.prevent="nextFocus($refs.refOperacion, 'combo', 200)"
                                      ref="refConductor">
                            </v-select>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
                            <label class="control-label label-sm">Operación</label>
                            <div class="">
                                <v-select
                                v-model="vselect.Operacion"
                                name="Operacion"
                                :clearable="true"
                                class="v-select-form"
                                label="Descripcion"
                                placeholder="--Seleccione--"
                                :options="list.Operacion"
                                v-on:keydown.enter.prevent="nextFocus($refs.refVehiculo, 'combo', 200)"
                                ref="refOperacion">
                            </v-select>
                            </div>
                        </div>
                    </div>
                    <div class="form-div" style="margin-bottom: 10px">
                        <div class="col-md-3 col-sm-3 col-xs-12 separate-in-form">
                            <label class="control-label label-sm">Tracto</label>
                            <div class="">
                                <v-select
                                v-model="vselect.Vehiculo"
                                name="Vehiculo"
                                :clearable="true"
                                class="v-select-form"
                                label="Descripcion"
                                placeholder="--Seleccione--"
                                :options="list.Vehiculo"
                                v-on:keydown.enter.prevent="nextFocus($refs.refEstado, 'combo', 200)"
                                ref="refVehiculo">
                            </v-select>
                            </div>
                        </div>
                        <div class="col-md-3 col-sm-3 col-xs-12 separate-in-form">
                            <label class="control-label label-sm">Estado</label>
                            <div class="">
                                <v-select
                                    v-model="vselect.Estado"
                                    name="Estado"
                                    :clearable="true"
                                    class="v-select-form"
                                    label="Descripcion"
                                    placeholder="--Seleccione--"
                                    :options="list.Estado"
                                    v-on:keydown.enter.prevent=""
                                    ref="refEstado">
                                </v-select>
                            </div>
                        </div>
                        
                    </div>
                    <div class="row">
                        <div class=" col-md-12 col-sm-12 col-xs-12  p-l-0 p-r-0 m-b-10 paginate-padding" style="overflow-x: auto" >
                        <table class="  table table-responsive table-hover table-bordered  custom-table" >
                            <thead>
                                <tr>
                                    <th>FECHA</th>
                                    <th>N° VIAJE</th>
                                    <th>OPERACIÓN</th>
                                    <th>TRACTO</th>
                                    <th>SEMI-REMOLQUE</th>
                                    <th>CONDUCTOR</th>
                                    <th>RUTA</th>
                                    <th>ESTADO VIAJE</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr 
                                v-for="(item, key) in list.TablaDetalle" :key="key + 1"
                                v-show="(pag - 1) * NUM_RESULTS <= key && pag * NUM_RESULTS > key">
                                    <td class="text-center">{{ item.dateHProgramming }}</td>
                                    <td class="text-center">
                                        <a @click="AddNew(item)" href="#" >{{ item.idProgramming }}</a>
                                    </td>
                                    <td class="text-left">{{ item.operation }}</td>
                                    <td class="text-left">{{ item.transportUnit1 }}</td>
                                    <td class="text-left">{{ item.transportUnit2 }}</td>
                                    <td class="text-left">{{ item.driverPerson1 }}</td>
                                    <td class="text-left">{{ item.route }}</td>
                                    <td class="text-left">{{ item.state }}</td>
                                </tr>
                            </tbody>
                        </table>
                        </div>
                        <div class="col-md-12 col-sm-12 col-xs-12 p-0" style="margin-top: 10px !important" >
                            <nav aria-label="Page navigation" class="text-center">
                                <ul class="pagination text-center pull-right">
                                    <li>
                                        <a href="#" aria-label="Previous" v-show="pag != 1" @click.prevent="pag -= 1" style="padding: 0px 0px 0px 0px" >
                                            <span aria-hidden="true">
                                                <button class="btn btn-info" style="height: 25px; line-height: 10px; color: #344767">
                                                <i class="fa fa-angle-double-left"></i>
                                                </button>
                                            </span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" aria-label="Next" v-show="(pag * NUM_RESULTS) / list.TablaDetalle.length < 1" @click.prevent="pag += 1" style="padding: 0px 10px 0px 5px" >
                                            <span aria-hidden="true">
                                                <button class="btn btn-info" style="height: 25px; line-height: 10px; color: #344767" >
                                                <i class="fa fa-angle-double-right"></i>
                                                </button>
                                            </span>
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>

                    </div>
                </div>
        </div>
     </div>

    <div class="p-0" v-show="!vshowAdd">
      <div class="Container">
        <div style="margin-bottom: 5px !important">
         <button  @click="AddNewReg" class="btn btn-success" style="margin-right: 3px !important">
                   <span style="margin-right: 5px"><i class="fa fa-arrow-left"></i></span>Regresar
         </button>
        </div>
        <div class="White-Box">
               <AddControl ref="refAddControl"></AddControl>
         </div>
      </div>
    </div>

</template>

<script>
   //import _ from 'lodash';
   import { APP } from "../../Content/base";
    import AddControl from "../ControlHseViajeProg/AddControl.vue"

    export default {
        name:"appVueControlHseViajeProg",
        el: "#appVueControlHseViajeProg",
        data() {
            return {
            filtro: {
                Desde: '',
                Hasta: '',
                Popup: {
                },
            },
            list: {
                Popup: {
                
                },
                Conductor: [],
                Operacion:[],
                Estado: [],
                Vehiculo: [],
                TablaDetalle: [],
            },
            vselect: {
                Conductor: null,
                Operacion: null,
                Estado:  null,
                Vehiculo: null,
                Popup: {
                
                },
            },
            paginate: ["items"],
            per: 10,
            NUM_RESULTS: 10, // Numero de resultados por página
            pag: 1, // Página inicial
            vshowAdd: true,
            };
        },
        async mounted() {
            let _this = this;

             await _this.getEstado();
             await _this.getOperacion();
             await _this.getVehiculo();
             await _this.geBusqueda();
        },
        components: {AddControl},
        methods: {
            nextFocus: async function (ref, type, time = 100) {
            setTimeout(() => {
                switch (type) {
                case "input":
                    ref.focus();
                    break;
                case "money":
                    ref.$refs.money.focus();
                    break;
                case "radio":
                    ref.$refs.input.focus();
                    break;
                case "check":
                    ref.$refs.input.focus();
                    break;
                case "combo":
                    ref.$refs.search.focus();
                    break;
                case "btn":
                    ref.focus();
                    break;
                default:
                    ref();
                }
            }, time);
            },
            AddNew: async function(item){
                    //this.$refs.refAddNotifica.cleanGrabado();
                    if(item){
                        this.$refs.refAddControl.getData(item);
                    }

                    setTimeout(async ()=>{
                        this.vshowAdd = !this.vshowAdd;
                        if(this.vshowAdd){
                            await this.geBusqueda();
                        }
                    },500);
            },
            AddNewReg: async function(){
                this.vshowAdd = !this.vshowAdd;
                this.$refs.refAddControl.cleanField();
                if(this.vshowAdd){
                    await this.geBusqueda();
                }
            },
            getConductor: async function (search) {
                let _this = this;
                if(search.length > 0){
                    this.axios.defaults.headers.common["Authorization"] = "Bearer " + JSON.parse(localStorage.getItem("SessionToken"));

                    await APP.Func.delayWhileIsSearching(); 

                    await this.axios
                        .get("api/netcore/GetConductor" + (search.length > 0 ? ('?search='+search) : '') )
                        .then((res) => {
                            if (res.data.EsCorrecto) {

                                _this.list.Conductor = res.data.Valor;

                            } else {
                                APP.msg.error(this.$toast, res.data.Mensaje);
                            }
                            })
                        .catch((error) => {
                            APP.msg.error(this.$toast,"Token expirado Inicie session..!!" + error);
                            localStorage.clear();
                            this.$store.state.isLogeado = false;
                            this.$router.push("/");
                        });
                    }
            },
            getEstado: async function () {
            let _this = this;
          
            this.axios.defaults.headers.common["Authorization"] = "Bearer " + JSON.parse(localStorage.getItem("SessionToken"));

            await this.axios
                .get("api/netcore/GetEstado")
                .then((res) => {
                    if (res.data.EsCorrecto) {

                        _this.list.Estado = res.data.Valor;

                    } else {
                        APP.msg.error(this.$toast, res.data.Mensaje);
                    }
                    })
                .catch((error) => {
                    APP.msg.error(this.$toast,"Token expirado Inicie session..!!" + error);
                    localStorage.clear();
                    this.$store.state.isLogeado = false;
                    this.$router.push("/");
                });
            },
            getOperacion: async function () {
                let _this = this;
            
                this.axios.defaults.headers.common["Authorization"] = "Bearer " + JSON.parse(localStorage.getItem("SessionToken"));

                await this.axios
                    .get("api/netcore/GetOperacion")
                    .then((res) => {
                        if (res.data.EsCorrecto) {

                            _this.list.Operacion = res.data.Valor;

                        } else {
                            APP.msg.error(this.$toast, res.data.Mensaje);
                        }
                        })
                    .catch((error) => {
                        APP.msg.error(this.$toast,"Token expirado Inicie session..!!" + error);
                        localStorage.clear();
                        this.$store.state.isLogeado = false;
                        this.$router.push("/");
                    });
            },
            getVehiculo: async function () {
                let _this = this;
            
                this.axios.defaults.headers.common["Authorization"] = "Bearer " + JSON.parse(localStorage.getItem("SessionToken"));

                await this.axios
                    .get("api/netcore/GetTipoVehiculo")
                    .then((res) => {
                        if (res.data.EsCorrecto) {

                            _this.list.Vehiculo = res.data.Valor;

                        } else {
                            APP.msg.error(this.$toast, res.data.Mensaje);
                        }
                        })
                    .catch((error) => {
                        APP.msg.error(this.$toast,"Token expirado Inicie session..!!" + error);
                        localStorage.clear();
                        this.$store.state.isLogeado = false;
                        this.$router.push("/");
                    });
            },
            geBusqueda: async function () {
            let _this = this;

                this.axios.defaults.headers.common["Authorization"] = "Bearer " + JSON.parse(localStorage.getItem("SessionToken"));

                var request = {
                    "dateI": _this.filtro.Desde,
                    "dateF": _this.filtro.Hasta,
                    "codDiverPerson": _this.vselect.Conductor ? _this.vselect.Conductor.Id : '',
                    "IdOperationHse": _this.vselect.Operacion ? parseInt(_this.vselect.Operacion.Id): 0,
                    "IdProgramming": "",
                    "IdState":  _this.vselect.Estado ? parseInt(_this.vselect.Estado.Id) : 0,
                    "CodtransportUnit1":_this.vselect.Vehiculo ? _this.vselect.Vehiculo.Id : '',
                    "CodtransportUnit2": ""
                };

                await this.axios
                    .post("api/programmingHSE/Search", request)
                    .then((res) => {
                    if (res.data.EsCorrecto) {

                        _this.list.TablaDetalle = res.data.Valor;

                        if( _this.list.TablaDetalle.length >0){
                        _this.pag = 1
                        }
                    
                    } else {
                        APP.msg.error(this.$toast, res.data.Mensaje);
                    }
                    })
                    .catch((error) => {
                    APP.msg.error(
                        this.$toast,
                        "Token expirado Inicie session..!!" + error
                    );
                    localStorage.clear();
                    this.$store.state.isLogeado = false;
                    this.$router.push("/");
                    });
            },
           
        },
        watch: {},
    }
</script>

<style lang="scss" scoped>
   
</style>