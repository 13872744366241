//APP.msg.confirm(this.$swal.fire, "Eliminar", "se eliminara la Tabla ¿Desea continuar?");
//APP.msg.success(this.$toast, "BIENVENIDOS ...!");
//import axios from "axios";

var APP = APP || {};

APP.UrlService = 'https://localhost:44330/';

APP.msg = {};

APP.msg.info = function(toast, text){
    toast.info(text,{position:'top-right',
    duration:4565,icon: 'info'});
}

APP.msg.success = function (toast, text){
    toast.success(text,{position:'top-right',
    duration:4565});
}

APP.msg.error = function(toast, text){
    toast.error(text,{position:'top-right',
    duration:4565});
}

APP.msg.warning = function(toast, text){
    toast.warning(text,{position:'top-right',
    duration:4565});
}

APP.msg.show = function(toast, text){
    toast.show(text,{position:'top-right',
    duration:4565});
}

APP.msg.confirm = async function(swal,_title, _message, _textButtonConfirm, _textButtonCancel) {
    var _bool = false;
    await swal({
        title: _title.toUpperCase() || 'MENSAJE DEL SISTEMA',
        text: _message || '¿Desea Continuar con la operación?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#1a4adc',
        cancelButtonColor: '#d33',
        confirmButtonText: _textButtonConfirm || 'Si',
        cancelButtonText: _textButtonCancel || 'No',
        focusCancel: true,
        allowOutsideClick: false
        //focusConfirm: true
      }).then(res => { if (res.value) _bool = res.value; })
      .catch(error => { alert(error); });
      return _bool;
}

APP.msg.refuse = async function (swal, _title, _message) {
    var auxBool = false;
    await swal({
        icon: 'error',
        title: _title.toUpperCase() || 'EXCEPCIÓN',
        text: _message || 'Ocurrió un problema',
        confirmButtonColor: '#1a4adc',
        // footer: '<a href="">Why do I have this issue?</a>',
        allowOutsideClick: false
      }).then(res => {
        if (res.value)
            auxBool = res.value;
    }).catch(error => {
        alert(error);
    });
    return auxBool;
};

APP.msg.image = async function (swal, _img, _title, _message, _width, _height) {
    var auxBool = false;
    await swal({
        title: _title.toUpperCase() || 'EXCEPCIÓN',
        text: _message || 'Ocurrió un problema',
        imageUrl: _img || 'https://image.shutterstock.com/z/stock-vector--exclamation-mark-exclamation-mark-hazard-warning-symbol-flat-design-style-vector-eps-444778462.jpg',
        imageAlt: 'Imagen no encontrada',
        animation: false,
        showCancelButton: true,
        imageWidth: parseInt(_width) || 200,
        imageHeight: parseInt(_height) || 200,
        // confirmButtonColor: '#DD6B55',
        // confirmButtonText: 'Si',
        // cancelButtonText: 'No',
        // focusCancel: true,
        // allowOutsideClick: false
      }).then(res => { if (res.value) auxBool = res.value; })
      .catch(error => { alert(error); });

    return auxBool;
};

APP.Func = {};
var consulta = null;
APP.Func.delayWhileIsSearching = async function (time = 400) {
    var retorno = false;

    clearTimeout(consulta);
    await new Promise(r => {
        consulta = setTimeout(r, time);
        retorno = true;
    });
    return retorno;
};


export {APP}