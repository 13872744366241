<template>
    <div class="Container">
            <div style="margin-bottom: 5px !important">
              <button class="btn btn-success" id="IdNewPopUp"  style="display: none; margin-right: 3px  !important" data-bs-toggle="modal" data-bs-target="#exampleModalContHoraSu">
                <span style="margin-right: 5px"><i class="fa fa-arrow-right"></i></span>Nuevo
              </button>
              <button @click="geBusqueda()" class="btn btn-primary" style="margin-right: 3px !important">
                <span style="margin-right: 5px"><i class="fa fa-search"></i></span>Buscar
              </button>
              <button @click="refrescar()" class="btn btn-inverse" style="margin-right: 3px !important; background: #344767 !important; color: white;">
                <span style="margin-right: 5px;"><i class="fa fa-refresh"></i></span>Refrescar
              </button>
            </div>
            <div class="White-Box">
                <div class="form-div" style="margin-bottom: 10px">
                    <div class="col-md-2 col-sm-2 col-xs-12 separate-in-form">
                        <label class="control-label label-sm">Desde</label>
                        <div class="">
                            <input  v-model="filtro.Desde"
                                    name="Desde"
                                    class="form-control input-sm"
                                    placeholder="Ingrese Descripción"
                                    ref="refDesde"
                                    type="date"
                                    autocomplete="off"/>
                        </div>
                    </div>
                    <div class="col-md-2 col-sm-2 col-xs-12 separate-in-form">
                        <label class="control-label label-sm">Hasta</label>
                        <div class="">
                            <input  v-model="filtro.Hasta"
                                    name="Hasta"
                                    class="form-control input-sm"
                                    placeholder="Ingrese Descripción"
                                    ref="refHasta"
                                    type="date"
                                    autocomplete="off"/>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
                        <label class="control-label label-sm">Conductor</label>
                        <div class="">
                            <v-select v-model="vselect.Conductor"
                                      name="Conductor"
                                      :clearable="true"
                                      class="v-select-form"
                                      label="Descripcion"
                                      placeholder="--Seleccione--"
                                      :options="list.Conductor"
                                      :filterable="false"
                                      @search="getConductor"
                                      v-on:keydown.enter.prevent="nextFocus($refs.refTipoRegistro, 'combo', 200)"
                                      ref="refConductor">
                            </v-select>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
                        <label class="control-label label-sm">Operación</label>
                        <div class="">
                            <v-select
                                v-model="vselect.Operacion"
                                name="Operacion"
                                :clearable="true"
                                class="v-select-form"
                                label="Descripcion"
                                placeholder="--Seleccione--"
                                :options="list.Operacion"
                                v-on:keydown.enter.prevent="nextFocus($refs.refGuia, 'input', 200)"
                                ref="refOperacion">
                            </v-select>
                        </div>
                    </div>
                </div>
                <div class="form-div" style="margin-bottom: 10px">
                    <div class="col-md-3 col-sm-3 col-xs-12 separate-in-form">
                        <label class="control-label label-sm">Riesgo</label>
                        <div class="">
                            <v-select
                                v-model="vselect.Riesgo"
                                name="Riesgo"
                                :clearable="false"
                                class="v-select-form"
                                label="Descripcion"
                                placeholder="--Seleccione--"
                                :options="list.Riesgo"
                                ref="refRiesgo">
                            </v-select>
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-3 col-xs-12 separate-in-form">
                        <label class="control-label label-sm">Estado</label>
                        <div class="">
                            <v-select
                                v-model="vselect.Estado"
                                name="Estado"
                                :clearable="true"
                                class="v-select-form"
                                label="Descripcion"
                                placeholder="--Seleccione--"
                                :options="list.Estado"
                                v-on:keydown.enter.prevent="nextFocus($refs.refOperacion, 'combo', 200)"
                                ref="refEstado">
                            </v-select>
                        </div>
                    </div>
                    
                </div>
                <div class="row">
                    <div class=" col-md-12 col-sm-12 col-xs-12  p-l-0 p-r-0 m-b-10 paginate-padding" style="overflow-x: auto" >
                    <table class="  table table-responsive table-hover table-bordered  custom-table" >
                        <thead>
                            <tr>
                                <th>FECHA</th>
                                <th>CONDUCTOR</th>
                                <th>N° VIAJE</th>
                                <th>OPERACIÓN</th>
                                <th>RUTA</th>
                                <th>HORAS SUEÑO</th>
                                <th>REM</th>
                                <th>RIESGO</th>
                                <th>ESTADO</th>
                                <!-- <th>ACCIONES</th> -->
                            </tr>
                        </thead>
                        <tbody>
                            <tr 
                            v-for="(item, key) in list.TablaDetalle" :key="key + 1"
                            v-show="(pag - 1) * NUM_RESULTS <= key && pag * NUM_RESULTS > key">
                                <td class="text-center">{{ item.fecha }}</td>
                                <td class="text-left">{{ item.responsable }}</td>
                                <td class="text-left">
                                    <a @click="getOpenDailyControles(item)" href="#" >{{ item.nroviaje }}</a>
                                </td>
                                <td class="text-left">{{ item.operacion }}</td>
                                <td class="text-left">{{ item.ruta }}</td>
                                <td class="text-left">{{ item.sleepTime }}</td>
                                <td class="text-left">{{ item.rem }}</td>
                                <td class="text-left">{{ item.riesgo }}</td>
                                <td class="text-left">{{ item.estado }}</td>
                            </tr>
                        </tbody>
                    </table>
                    </div>
                    <div class="col-md-12 col-sm-12 col-xs-12 p-0" style="margin-top: 10px !important" >
                        <nav aria-label="Page navigation" class="text-center">
                            <ul class="pagination text-center pull-right">
                                <li>
                                    <a href="#" aria-label="Previous" v-show="pag != 1" @click.prevent="pag -= 1" style="padding: 0px 0px 0px 0px" >
                                        <span aria-hidden="true">
                                            <button class="btn btn-info" style="height: 25px; line-height: 10px; color: #344767">
                                            <i class="fa fa-angle-double-left"></i>
                                            </button>
                                        </span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#" aria-label="Next" v-show="(pag * NUM_RESULTS) / list.TablaDetalle.length < 1" @click.prevent="pag += 1" style="padding: 0px 10px 0px 5px" >
                                        <span aria-hidden="true">
                                            <button class="btn btn-info" style="height: 25px; line-height: 10px; color: #344767" >
                                            <i class="fa fa-angle-double-right"></i>
                                            </button>
                                        </span>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>

                </div>
            </div>
    </div>

 <!-- Modal -->
  <div
    class="modal fade"
    id="exampleModalContHoraSu"
    tabindex="-1"
    data-bs-backdrop="static"
    aria-labelledby="exampleModalContHoraSuLabel"
    aria-hidden="true"
    role="dialog">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalContHoraSuLabel">
            Control detalle Hora Sueño
          </h5>
          <button @click="cleanField()"
            type="button"
            id="IdcloseModal1"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="form-div col-md-12 col-sm-12 col-xs-12 p-0">
            <div class="form-div col-md-8 col-sm-8 col-xs-12 p-0">
              <div class="col-md-12 col-sm-12 col-xs-12 p-0" style="margin-top:10px; border: 1px solid #a5a6bc; border-radius: 5px !important;">
                <h1 style=" width: 170px; font-size: 12px; margin-top: -10px; margin-left: 7px; background: white; font-weight: 500;margin-bottom: 0px !important; color: #337ab7;">Datos Generales Programación</h1>
                <div class="form-div col-md-12 col-sm-12 col-xs-12 p-0">
                    <div class="form-div col-md-6 col-sm-6 col-xs-12 p-0">
                        <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
                            <label class="control-label label-sm" style="font-weight: 700;">Inicio Ruta:</label>
                        </div>
                        <div class="col-md-8 col-sm-8 col-xs-12 separate-in-form">
                            <label class="control-label label-sm">{{ItemPopup.dateProgramming}}</label>
                        </div>
                    </div>
                    <div class="form-div col-md-6 col-sm-6 col-xs-12 p-0">
                        <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
                            <label class="control-label label-sm" style="font-weight: 700;">Nro Viaje:</label>
                        </div>
                        <div class="col-md-8 col-sm-8 col-xs-12 separate-in-form">
                            <label class="control-label label-sm">{{ItemPopup.idProgramming}}</label>
                        </div>
                    </div>
                </div>
                <div class="form-div col-md-12 col-sm-12 col-xs-12 p-0">
                    <div class="form-div col-md-6 col-sm-6 col-xs-12 p-0">
                        <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
                            <label class="control-label label-sm" style="font-weight: 700;">Operación:</label>
                        </div>
                        <div class="col-md-8 col-sm-8 col-xs-12 separate-in-form">
                            <label class="control-label label-sm">{{ItemPopup.operationHSE}}</label>
                        </div>
                    </div>
                    <div class="form-div col-md-6 col-sm-6 col-xs-12 p-0">
                        <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
                            <label class="control-label label-sm" style="font-weight: 700;">Conductor:</label>
                        </div>
                        <div class="col-md-8 col-sm-8 col-xs-12 separate-in-form">
                            <label class="control-label label-sm">{{ItemPopup.DiverPerson1}}</label>
                        </div>
                    </div>
                </div>
                <div class="form-div col-md-12 col-sm-12 col-xs-12 p-0">
                    <div class="form-div col-md-6 col-sm-6 col-xs-12 p-0">
                        <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
                            <label class="control-label label-sm" style="font-weight: 700;">Tracto:</label>
                        </div>
                        <div class="col-md-8 col-sm-8 col-xs-12 separate-in-form">
                            <label class="control-label label-sm">{{ItemPopup.transportUnit1}}</label>
                        </div>
                    </div>
                    <div class="form-div col-md-6 col-sm-6 col-xs-12 p-0">
                        <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
                            <label class="control-label label-sm" style="font-weight: 700;">Semi Remolque:</label>
                        </div>
                        <div class="col-md-8 col-sm-8 col-xs-12 separate-in-form">
                            <label class="control-label label-sm">{{ItemPopup.transportUnit2}}</label>
                        </div>
                    </div>
                </div>
                <div class="form-div col-md-12 col-sm-12 col-xs-12 p-0">
                    <div class="form-div col-md-6 col-sm-6 col-xs-12 p-0">
                        <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
                            <label class="control-label label-sm" style="font-weight: 700;">Ruta:</label>
                        </div>
                        <div class="col-md-8 col-sm-8 col-xs-12 separate-in-form">
                            <label class="control-label label-sm">{{ItemPopup.origin}} - {{ ItemPopup.destination }}</label>
                        </div>
                    </div>
                    <div class="form-div col-md-6 col-sm-6 col-xs-12 p-0">
                        <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
                            <label class="control-label label-sm" style="font-weight: 700;">Guia:</label>
                        </div>
                        <div class="col-md-8 col-sm-8 col-xs-12 separate-in-form">
                            <label class="control-label label-sm">{{ItemPopup.despatchAdvice}}</label>
                        </div>
                    </div>
                </div>
              </div>
              <div class="col-md-12 col-sm-12 col-xs-12 p-0" style="margin-top:20px; border: 1px solid #a5a6bc; border-radius: 5px !important;">
                <h1 style=" width: 118px; font-size: 12px; margin-top: -10px; margin-left: 7px; background: white; font-weight: 500;margin-bottom: 0px !important; color: #337ab7;">Datos Registro Diario</h1>
                <div class="form-div col-md-12 col-sm-12 col-xs-12 p-0">
                    <div class="form-div col-md-6 col-sm-6 col-xs-12 p-0">
                        <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
                            <label class="control-label label-sm" style="font-weight: 700;">Fecha - Hora - dia:</label>
                        </div>
                        <div class="col-md-8 col-sm-8 col-xs-12 separate-in-form">
                            <label class="control-label label-sm">{{ItemPopup.dateRegister}} &nbsp;&nbsp;&nbsp; dia 1</label>
                        </div>
                    </div>
                    <div class="form-div col-md-6 col-sm-6 col-xs-12 p-0">
                        <div class="col-md-5 col-sm-5 col-xs-12 separate-in-form">
                            <label class="control-label label-sm" style="font-weight: 700;">Horas de Sueño:</label>
                        </div>
                        <div class="col-md-7 col-sm-7 col-xs-12 separate-in-form">
                            <label class="control-label label-sm">{{filtro.Popup.HorasSueño}}</label>
                        </div>
                    </div>
                </div>
                <div class="form-div col-md-12 col-sm-12 col-xs-12 p-0" style="margin-bottom: 10px;">
                    <div class="form-div col-md-6 col-sm-6 col-xs-12 p-0">
                        <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
                            <label class="control-label label-sm" style="font-weight: 700;">REM:</label>
                        </div>
                        <div class="col-md-8 col-sm-8 col-xs-12 separate-in-form">
                            <label class="control-label label-sm">{{filtro.Popup.Rem}}%</label>
                        </div>
                    </div>
                    <div class="form-div col-md-6 col-sm-6 col-xs-12 p-0">
                        <div class="col-md-5 col-sm-5 col-xs-12 separate-in-form">
                            <label class="control-label label-sm" style="font-weight: 700;">Riesgo:</label>
                        </div>
                        <div class="col-md-7 col-sm-7 col-xs-12 separate-in-form">
                            <label v-show="filtro.Popup.Riesgo === '1'" class="control-label label-sm" style="background: #519646;padding: 1px 10px;color: white;border-radius: 5px;width: 50%;height: 22px;text-align: center;">Bajo</label>
                            <label v-show="filtro.Popup.Riesgo === '2'" class="control-label label-sm" style="background: #eadd0d;padding: 1px 10px;color: black;border-radius: 5px;width: 50%;height: 22px;text-align: center;">Moderado</label>
                            <label v-show="filtro.Popup.Riesgo === '3'" class="control-label label-sm" style="background: #f39300;padding: 1px 10px;color: white;border-radius: 5px;width: 50%;height: 22px;text-align: center;">Alto</label>
                            <label v-show="filtro.Popup.Riesgo === '4'" class="control-label label-sm" style="background: red;padding: 1px 10px;color: white;border-radius: 5px;width: 50%;height: 22px;text-align: center;">Critico</label>
                        </div>
                    </div>
                </div>
                <div class="form-div col-md-12 col-sm-12 col-xs-12 p-0" style="margin-bottom: 10px;">
                    <div class="form-div col-md-6 col-sm-6 col-xs-12 p-0">
                        <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
                            <label class="control-label label-sm" style="font-weight: 700;">Adjunto y/o Evid.:</label>
                        </div>
                        <div class="col-md-8 col-sm-8 col-xs-12 separate-in-form">
                            <button class="btn btn-danger" data-bs-toggle="modal" data-bs-target="#exampleModalArchAD" style="height: 25px; line-height: 1px;">
                                <span style="margin-right: 5px"><i class="fa fa-image"></i></span>Ver Imagen
                            </button>
                        </div>
                    </div>
                    <div class="form-div col-md-6 col-sm-6 col-xs-12 p-0">
                        <!-- <div class="col-md-5 col-sm-5 col-xs-12 separate-in-form">
                            <label class="control-label label-sm" style="font-weight: 700;">Declaración Jurada Fat.:</label>
                        </div>
                        <div class="col-md-7 col-sm-7 col-xs-12 separate-in-form">
                            <button class="btn btn-primary" style="height: 25px; line-height: 1px;">
                                <span style="margin-right: 5px"><i class="fa fa-download"></i></span>Adjuntar
                            </button>
                        </div> -->
                    </div>
                </div>
                <div class="form-div col-md-12 col-sm-12 col-xs-12 p-0">
                    <div class="form-div col-md-12 col-sm-12 col-xs-12 p-0">
                        <div class="col-md-2 col-sm-2 col-xs-12 separate-in-form">
                            <label class="control-label label-sm" style="font-weight: 700;">Observación:</label>
                        </div>
                        <div class="col-md-9 col-sm-9 col-xs-12 separate-in-form">
                            <textarea  v-model="filtro.Observacion"
                                    name="Observacion"
                                    class="form-control input-sm"
                                    placeholder="Ingrese Observacion"
                                    ref="refObservacion"
                                    rows="4"
                                    autocomplete="off"
                                    style="min-height: 50px;"
                                    disabled>
                            </textarea>
                        </div>
                    </div>
                </div>

                <div class="form-div col-md-12 col-sm-12 col-xs-12 p-0" style="margin: 10px 0px !important;">
                    <div class="form-div col-md-12 col-sm-12 col-xs-12 p-0">
                        <div class="col-md-2 col-sm-2 col-xs-12 separate-in-form">
                            <label class="control-label label-sm" style="font-weight: 700;">Estado:</label>
                        </div>
                        <div class="col-md-2 col-sm-2 col-xs-12 separate-in-form">
                                <label class="control-label label-sm" style="font-weight: 700; color: #1625d9;">{{ItemPopup.dailyState}}</label>
                            </div>
                        <div class="form-div col-md-8 col-sm-8 col-xs-12 p-0" v-show="ItemPopup.idState !== 3">
                            <div class="col-md-6 col-sm-6 col-xs-12 separate-in-form" v-show="ItemPopup.idState === 1">
                                        <button class="btn btn-danger" data-bs-toggle="modal" data-bs-target="#exampleModalRefuseC" style="margin-right: 3px !important">
                                            <span style="margin-right: 5px"><i class=""></i></span>OBSERVAR
                                        </button>
                            </div>
                            <div class="col-md-6 col-sm-6 col-xs-12 separate-in-form" v-show="ItemPopup.idState === 2 || ItemPopup.idState === 1">
                                        <button @click="getAutorice()" class="btn btn-success" style="margin-right: 3px !important">
                                            <span style="margin-right: 5px"><i class=""></i></span>AUTORIZAR
                                        </button>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
                <div class=" col-md-12 col-sm-12 col-xs-12 p-l-0 p-r-0 m-t-10 m-b-10 paginate-padding " style="overflow-x: auto; margin-top:10px">
                    <table class=" table table-responsive table-hover table-bordered custom-table ">
                        <thead>
                            <tr>
                                <th>OBSERVACIÓN</th>
                                <!-- <th>RESPONSABLE</th> -->
                                <th>ESTADO</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, key) in list.Popup.dailAudit" :key="key + 1">
                                <td class="text-left">{{ item.observation }}</td>
                                <td class="text-center">{{ item.dailyState }}</td>
                                <!-- <td class="text-rigth">{{ item.diasFaltantes }}</td> -->
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
          </div>
          <hr/>
        
       </div>
      </div>
    </div>
  </div>

   <!-- Modal 2 -->
   <div
            class="modal fade"
            id="exampleModalRefuseC"
            tabindex="-1"
            data-bs-backdrop="static"
            aria-labelledby="exampleModalRefuseCLabel"
            aria-hidden="true">
            <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                <h5 class="modal-title" id="exampleModalRefuseCLabel">
                    Registro Observado
                </h5>
                <button @click="cleanField()"
                    type="button"
                    id="IdCloseModal2c"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                ></button>
                </div>
                <div class="modal-body">
                <div class="form-div col-md-12 col-sm-12 col-xs-12 p-0">
                    <div class="form-div col-md-12 col-sm-12 col-xs-12 p-0">
                        <div class="col-md-12 col-sm-12 col-xs-12 separate-in-form">
                            <label class="control-label label-sm">Motivo de registro observado (Solo cuando sea observado)</label>
                            <div class="">
                            <textarea v-model="filtro.Popup2.Observacion"
                                        name="Observacion"
                                        placeholder="Ingrese Observacion"
                                        ref="refObservacionPopup2"
                                        autocomplete="off"
                                        rows="4"
                                        style="width: 100%;">
                            </textarea>
                            </div>
                        </div> 
                    </div>
                </div>
                <hr/>
                
            </div>
            <div class="modal-footer">
                <button @click="getRefuse()" type="button" class="btn btn-primary" ref="btnGrabar1">
                    <i class="fa fa-save" style="margin-right: 5px"></i>Grabar
                </button>
            </div>
            </div>
            </div>
    </div>

 <!-- Modal 3 -->
 <div
    class="modal fade"
    id="exampleModalArchAD"
    tabindex="-1"
    data-bs-backdrop="static"
    aria-labelledby="exampleModalArchADLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-lg">
    <div class="modal-content">
        <div class="modal-header">
        <h5 class="modal-title" id="exampleModalArchADLabel">
           Archivo Adjunto
        </h5>
        <button @click="closeImagen()"
            type="button"
            id="IdCloseModaImagen"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
        ></button>
        <button
            data-bs-toggle="modal" 
            data-bs-target="#exampleModalContHoraSu"
            type="button"
            id="IdOpenCs"
           style="display: none;"
        ></button>
        </div>
        <div class="modal-body">
                <div class="col-md-12 col-sm-12 col-xs-12 separate-in-form" style="text-align: center;">
                    <template v-if="filtro.Popup.UrlImgArchivoAdj != ''">
                        <img draggable="false" id="IdUrlImgArchivoAdj1"  :src="filtro.Popup.UrlImgArchivoAdj" style="width: 80%;"/>
                    </template>
                    <template v-else>
                        <img draggable="false" id="IdUrlImgArchivoAdj" src="../../assets/img/NoImagen.jpg" style="width: 80%;"/>
                    </template>
                    <!-- <a href="imagen.jpeg" download><img :src="filtro.Popup.UrlImgArchivoAdj" alt="imagen de ejemplo"/></a> -->
                    <!-- <button id="BtnDescargar" type="btn btn-focus btn-primary" @click="descargarImagen(filtro.Popup.UrlImgArchivoAdj)">Descargar</button> -->
                    <!-- <iframe :src="filtro.Popup.UrlImgArchivoAdj" ></iframe> -->
                </div> 
        <!-- <hr/> -->
        
    </div>
    <!-- <div class="modal-footer">
        <button @click="getRefuse()" type="button" class="btn btn-primary" ref="btnGrabar1">
            <i class="fa fa-save" style="margin-right: 5px"></i>Grabar
        </button>
    </div> -->
    </div>
    </div>
    </div>

</template>

<script>
   import _ from 'lodash';
   import { APP } from "../../Content/base";

    export default {
        name:"appVueControlHoraSuenio",
        el: "#appVueControlHoraSuenio",
        data() {
            return {
            filtro: {
                Desde: '',
                Hasta: '',
                Popup: {
                    idDaily: 0,
                    HorasSueño: '',
                    Rem: '',
                    Riesgo: '',
                    UrlImgArchivoAdj: '',
                },
                Popup2:{
                    idDaily: 0,
                    Observacion: '',
                },
            },
            list: {
                Riesgo: [ 
                    {Id: 1, Descripcion: 'BAJO' },
                    {Id: 2, Descripcion: 'MODERADO' },
                    {Id: 3, Descripcion: 'ALTO' },
                    {Id: 4, Descripcion: 'CRITICO' },
                ],
                Conductor: [],
                Operacion:[],
                Estado: [],
                Popup: {
                    dailAudit:[],
                },

                TablaDetalle: [],
            },
            vselect: {
                Riesgo: null,
                Conductor: null,
                Operacion: null,
                Estado: null,
                Popup: {
                
                },
            },
            ItemPopup:{},
            ItemFila: {},
            paginate: ["items"],
            per: 10,
            NUM_RESULTS: 10, // Numero de resultados por página
            pag: 1, // Página inicial
            vshowSoat: true,
            };
        },
        async mounted() {
            let _this = this;

            await _this.getEstado();
            await _this.getOperacion();
            await _this.geBusqueda();
        },
        components: {},
        methods: {
            nextFocus: async function (ref, type, time = 100) {
            setTimeout(() => {
                switch (type) {
                case "input":
                    ref.focus();
                    break;
                case "money":
                    ref.$refs.money.focus();
                    break;
                case "radio":
                    ref.$refs.input.focus();
                    break;
                case "check":
                    ref.$refs.input.focus();
                    break;
                case "combo":
                    ref.$refs.search.focus();
                    break;
                case "btn":
                    ref.focus();
                    break;
                default:
                    ref();
                }
            }, time);
            },
            getConductor: async function (search) {
                let _this = this;
                if(search.length > 0){
                    this.axios.defaults.headers.common["Authorization"] = "Bearer " + JSON.parse(localStorage.getItem("SessionToken"));

                    await APP.Func.delayWhileIsSearching(); 

                    await this.axios
                        .get("api/netcore/GetConductor" + (search.length > 0 ? ('?search='+search) : '') )
                        .then((res) => {
                            if (res.data.EsCorrecto) {

                                _this.list.Conductor = res.data.Valor;

                            } else {
                                APP.msg.error(this.$toast, res.data.Mensaje);
                            }
                            })
                        .catch((error) => {
                            APP.msg.error(this.$toast,"Token expirado Inicie session..!!" + error);
                            localStorage.clear();
                            this.$store.state.isLogeado = false;
                            this.$router.push("/");
                        });
                    }
            },
            getEstado: async function () {
            let _this = this;
          
            this.axios.defaults.headers.common["Authorization"] = "Bearer " + JSON.parse(localStorage.getItem("SessionToken"));

            await this.axios
                .get("api/netcore/GetEstado")
                .then((res) => {
                    if (res.data.EsCorrecto) {

                        _this.list.Estado = res.data.Valor;

                    } else {
                        APP.msg.error(this.$toast, res.data.Mensaje);
                    }
                    })
                .catch((error) => {
                    APP.msg.error(this.$toast,"Token expirado Inicie session..!!" + error);
                    localStorage.clear();
                    this.$store.state.isLogeado = false;
                    this.$router.push("/");
                });
            },
            getOperacion: async function () {
                let _this = this;
            
                this.axios.defaults.headers.common["Authorization"] = "Bearer " + JSON.parse(localStorage.getItem("SessionToken"));

                await this.axios
                    .get("api/netcore/GetOperacion")
                    .then((res) => {
                        if (res.data.EsCorrecto) {

                            _this.list.Operacion = res.data.Valor;

                        } else {
                            APP.msg.error(this.$toast, res.data.Mensaje);
                        }
                        })
                    .catch((error) => {
                        APP.msg.error(this.$toast,"Token expirado Inicie session..!!" + error);
                        localStorage.clear();
                        this.$store.state.isLogeado = false;
                        this.$router.push("/");
                    });
            },
            geBusqueda: async function () {
            let _this = this;

            this.axios.defaults.headers.common["Authorization"] = "Bearer " + JSON.parse(localStorage.getItem("SessionToken"));

            var request = {

                "fechaInicio": _this.filtro.Desde,
                "fechaFin": _this.filtro.Hasta,
                "idChofer":_this.vselect.Conductor ? _this.vselect.Conductor.Id : '',
                "idState": _this.vselect.Estado ? parseInt(_this.vselect.Estado.Id) : 0,
                "idOperationHse": _this.vselect.Operacion ? parseInt(_this.vselect.Operacion.Id): 0,
                "idRiesgo": _this.vselect.Riesgo ? parseInt(_this.vselect.Riesgo.Id): 0
            };

            await this.axios
                .post("api/controlesDiario/selectSleepTime", request)
                .then((res) => {
                if (res.data.EsCorrecto) {

                    _this.list.TablaDetalle = res.data.Valor;

                    if( _this.list.TablaDetalle.length >0){
                    _this.pag = 1
                    }
                
                } else {
                    APP.msg.error(this.$toast, res.data.Mensaje);
                }
                })
                .catch((error) => {
                APP.msg.error(
                    this.$toast,
                    "Token expirado Inicie session..!!" + error
                );
                localStorage.clear();
                this.$store.state.isLogeado = false;
                this.$router.push("/");
                });
            },
            getOpenDailyControles: async function (_Item) {
                let _this = this;
                
                    //_this.CleanPopUps();
                    this.axios.defaults.headers.common["Authorization"] = "Bearer " + JSON.parse(localStorage.getItem("SessionToken"));

                    _this.filtro.Popup2.idDaily = _Item.idDaily;
                    _this.ItemFila = _Item;

                    await this.axios
                        .get("api/controlesDiario/get/" + (_Item.idDaily > 0 ? String(_Item.idDaily) : ''))
                        .then((res) => {
                            if (res.data.EsCorrecto) {

                            var _Data = _.cloneDeep(res.data.Valor);

                            if(_Data){
                                    _this.ItemPopup = _Data;

                                    for (var i = 0; i < _Data.dailyParam.length ; i++) {
                                       if(_Data.dailyParam[i].codParam === 1){
                                        _this.filtro.Popup.UrlImgArchivoAdj = _Data.dailyParam[i].valueParam;
                                       }
                                       if(_Data.dailyParam[i].codParam === 3){
                                        _this.filtro.Popup.HorasSueño = _Data.dailyParam[i].valueParam;
                                       }
                                       if(_Data.dailyParam[i].codParam === 4){
                                        _this.filtro.Popup.Rem = _Data.dailyParam[i].valueParam;
                                       }
                                       if(_Data.dailyParam[i].codParam === 5){
                                        _this.filtro.Popup.Riesgo = _Data.dailyParam[i].valueParam;
                                       }
                                    }
                                    
                                    _this.list.Popup.dailAudit = _.cloneDeep(_Data.dailAudit);
                                    

                                    document.getElementById('IdNewPopUp').click();
                            }
                            
                            } else {
                                APP.msg.error(this.$toast, res.data.Mensaje);
                            }
                            })
                        .catch((error) => {
                            APP.msg.error(this.$toast,"Token expirado Inicie session..!!" + error);
                            localStorage.clear();
                            this.$store.state.isLogeado = false;
                            this.$router.push("/");
                        });
            },
            cleanField: async function(){
                let _this = this;

                _this.ItemFila = {};
                _this.filtro.Popup.idDaily = 0;
                _this.filtro.Popup.HorasSueño = '';
                _this.filtro.Popup.Rem= '';
                _this.filtro.Popup.Riesgo= '';
                _this.filtro.Popup.UrlImgArchivoAdj= '';
            },
            getAutorice: async function () {
            let _this = this;

                this.axios.defaults.headers.common["Authorization"] = "Bearer " + JSON.parse(localStorage.getItem("SessionToken"));
                var usuario = JSON.parse(localStorage.getItem("Usuario"));

                var request = {
                    "idDaily":  parseInt(_this.filtro.Popup2.idDaily) || 0,
                    "usrRegister": usuario ? parseInt(usuario.UsuarioId) : 0,
                };

                await this.axios
                    .post("api/controlesDiario/authorize", request)
                    .then((res) => {
                        if (res.data.EsCorrecto) {
                            document.getElementById("IdcloseModal1").click();
                            APP.msg.success( this.$toast, "Se autorizo correctamente..!!");

                        } else {
                            APP.msg.error(this.$toast, res.data.Mensaje);
                        }
                    })
                    .catch((error) => {
                    APP.msg.error( this.$toast, "Token expirado Inicie session..!!" + error );
                    localStorage.clear();
                    this.$store.state.isLogeado = false;
                    this.$router.push("/");
                    });


            },
            getRefuse: async function () {
            let _this = this;

            if(_this.filtro.Popup2.Observacion != ''){
                this.axios.defaults.headers.common["Authorization"] = "Bearer " + JSON.parse(localStorage.getItem("SessionToken"));
                var usuario = JSON.parse(localStorage.getItem("Usuario"));

                var request = {
                    "idDaily": parseInt(_this.filtro.Popup2.idDaily) || 0,
                    "usrRegister": usuario ? parseInt(usuario.UsuarioId) : 0,
                    "reason": String(_this.filtro.Popup2.Observacion) || '',
                };

                await this.axios
                    .post("api/controlesDiario/refuse", request)
                    .then((res) => {
                        if (res.data.EsCorrecto) {
                            document.getElementById("IdCloseModal2c").click();
                            APP.msg.success( this.$toast, "Se Observo correctamente..!!");
                        
                        } else {
                            APP.msg.error(this.$toast, res.data.Mensaje);
                        }
                    })
                    .catch((error) => {
                    APP.msg.error( this.$toast, "Token expirado Inicie session..!!" + error );
                    localStorage.clear();
                    this.$store.state.isLogeado = false;
                    this.$router.push("/");
                    });

            }else{
                APP.msg.warning(this.$toast, "Ingrese Observación...!");
                _this.nextFocus(this.$refs.refObservacionPopup2, 'input', 200);
            }

            },
            closeImagen: async function(){
                await this.getOpenDailyControles(this.ItemFila);
                document.getElementById('IdOpenCs').click();
            },
            refrescar: async function(){
                let _this = this;

                _this.filtro.Desde = '';
                _this.filtro.Hasta = '';
                _this.vselect.Conductor = null;
                _this.vselect.Riesgo = null;
                _this.vselect.Estado = null;
                _this.vselect.Operacion = null;
                
                await _this.geBusqueda();
            },
            descargarImagen: async function(url){

                url = "https://api.wariservice.com.pe/storage//imgs/c633a36a-ab38-4458-b8bd-0946df94d3ae.jpeg";

                const response = await fetch(url,{ mode : 'no-cors', });
                const blobImage = await response.blob();
                const href = URL.createObjectURL(blobImage);
                const anchorElement = document.createElement('a');
                anchorElement.href = href;
                anchorElement.download = 'favicon.png'; 
                document.body.appendChild(anchorElement);
                anchorElement.click();
                document.body.removeChild(anchorElement);
                window.URL.revokeObjectURL(href);
            },

        },
        watch: {},
    }
</script>

<style lang="scss" scoped>

</style>