<template>
       <div class="Container">
            <div style="margin-bottom: 5px !important">
              <button class="btn btn-success" style="margin-right: 3px !important; display: none;" data-bs-toggle="modal" data-bs-target="#exampleModal" id="IdOpenModal">
                <span style="margin-right: 5px"><i class="fa fa-arrow-right"></i></span>Nuevo
              </button>
              <button @click="geBusqueda()" class="btn btn-primary" style="margin-right: 3px !important">
                <span style="margin-right: 5px"><i class="fa fa-search"></i></span>Buscar
              </button>
              <button @click="refrescar()" class="btn btn-inverse" style="margin-right: 3px !important; background: #344767 !important; color: white;">
                <span style="margin-right: 5px;"><i class="fa fa-refresh"></i></span>Refrescar
              </button>
            </div>
            <div class="White-Box">
                <div class="form-div" style="margin-bottom: 10px">
                    <div class="col-md-2 col-sm-2 col-xs-12 separate-in-form">
                        <label class="control-label label-sm">Desde</label>
                        <div class="">
                            <input  v-model="filtro.Desde"
                                    name="Desde"
                                    class="form-control input-sm"
                                    placeholder="Ingrese Descripción"
                                    ref="refDesde"
                                    @keyup.enter="nextFocus($refs.refHasta, 'input', 200)"
                                    type="date"
                                    autocomplete="off"/>
                        </div>
                    </div>
                    <div class="col-md-2 col-sm-2 col-xs-12 separate-in-form">
                        <label class="control-label label-sm">Hasta</label>
                        <div class="">
                            <input  v-model="filtro.Hasta"
                                    name="Hasta"
                                    class="form-control input-sm"
                                    placeholder="Ingrese Descripción"
                                    ref="refHasta"
                                    @keyup.enter="nextFocus($refs.refConductor, 'combo', 200)"
                                    type="date"
                                    autocomplete="off"/>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
                        <label class="control-label label-sm">Conductor</label>
                        <div class="">
                            <v-select v-model="vselect.Conductor"
                                      name="Conductor"
                                      :clearable="true"
                                      class="v-select-form"
                                      label="Descripcion"
                                      placeholder="--Seleccione--"
                                      :options="list.Conductor"
                                      :filterable="false"
                                      @search="getConductor"
                                      v-on:keydown.enter.prevent="nextFocus($refs.refTipoRegistro, 'combo', 200)"
                                      ref="refConductor">
                            </v-select>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
                        <label class="control-label label-sm">Tipo Registro</label>
                        <div class="">
                            <v-select
                                v-model="vselect.TipoRegistro"
                                name="TipoRegistro"
                                :clearable="true"
                                class="v-select-form"
                                label="Descripcion"
                                placeholder="--Seleccione--"
                                :options="list.TipoRegistro"
                                v-on:keydown.enter.prevent="nextFocus($refs.refEstado, 'combo', 200)"
                                ref="refTipoRegistro">
                            </v-select>
                        </div>
                    </div>
                </div>
                <div class="form-div" style="margin-bottom: 10px">
                    <div class="col-md-3 col-sm-3 col-xs-12 separate-in-form">
                        <label class="control-label label-sm">Estado</label>
                        <div class="">
                            <v-select
                                v-model="vselect.Estado"
                                name="Estado"
                                :clearable="true"
                                class="v-select-form"
                                label="Descripcion"
                                placeholder="--Seleccione--"
                                :options="list.Estado"
                                v-on:keydown.enter.prevent="nextFocus($refs.refOperacion, 'combo', 200)"
                                ref="refEstado">
                            </v-select>
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-3 col-xs-12 separate-in-form">
                        <label class="control-label label-sm">Operación</label>
                        <div class="">
                            <v-select
                                v-model="vselect.Operacion"
                                name="Operacion"
                                :clearable="true"
                                class="v-select-form"
                                label="Descripcion"
                                placeholder="--Seleccione--"
                                :options="list.Operacion"
                                v-on:keydown.enter.prevent="nextFocus($refs.refGuia, 'input', 200)"
                                ref="refOperacion">
                                <!-- <template v-slot:selection="{ item }">
                                    <div>{{ item.Id }}-{{ item.Descripcion }}</div>
                                </template>
                                <template v-slot:item="{ item }">
                                    <div>{{ item.Id }}-{{ item.Descripcion }}</div>
                                </template> -->
                            </v-select>
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-3 col-xs-12 separate-in-form">
                        <label class="control-label label-sm">Guia</label>
                        <div class="">
                            <input  v-model="filtro.Guia"
                                    name="Guia"
                                    class="form-control input-sm"
                                    placeholder="Ingrese Guia"
                                    ref="refGuia"
                                    autocomplete="off"/>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class=" col-md-12 col-sm-12 col-xs-12  p-l-0 p-r-0 m-b-10 paginate-padding" style="overflow-x: auto" >
                    <table class="  table table-responsive table-hover table-bordered  custom-table" >
                        <thead>
                            <tr>
                                <th>FECHA</th>
                                <th>REGISTRO</th>
                                <th>CONDUCTOR</th>
                                <th>N° VIAJE</th>
                                <th>OPERACIÓN</th>
                                <th>RUTA</th>
                                <th>UNIDAD</th>
                                <th>ESTADO</th>
                                <!-- <th>ACCIONES</th> -->
                            </tr>
                        </thead>
                        <tbody>
                            <tr 
                            v-for="(item, key) in list.TablaDetalle" :key="key + 1"
                            v-show="(pag - 1) * NUM_RESULTS <= key && pag * NUM_RESULTS > key">
                                <td class="text-left">{{ item.FECHA }}</td>
                                <td class="text-center">{{ item.REGISTRO }}</td>
                                <td class="text-left">{{ item.RESPONSABLE }}</td>
                                <td class="text-left">
                                    <a @click="getOpenDailyControles(item)" href="#" >{{ item.NROVIAJE }}</a>
                                </td>
                                <td class="text-left">{{ item.OPERACION }}</td>
                                <td class="text-left">{{ item.RUTA }}</td>
                                <td class="text-left">{{ item.PLACA_UMP }}</td>
                                <td class="text-left">{{ item.ESTADO }}</td>
                            </tr>
                        </tbody>
                    </table>
                    </div>
                    <div class="col-md-12 col-sm-12 col-xs-12 p-0" style="margin-top: 10px !important" >
                        <nav aria-label="Page navigation" class="text-center">
                            <ul class="pagination text-center pull-right">
                                <li>
                                    <a href="#" aria-label="Previous" v-show="pag != 1" @click.prevent="pag -= 1" style="padding: 0px 0px 0px 0px" >
                                        <span aria-hidden="true">
                                            <button class="btn btn-info" style="height: 25px; line-height: 10px; color: #344767">
                                            <i class="fa fa-angle-double-left"></i>
                                            </button>
                                        </span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#" aria-label="Next" v-show="(pag * NUM_RESULTS) / list.TablaDetalle.length < 1" @click.prevent="pag += 1" style="padding: 0px 10px 0px 5px" >
                                        <span aria-hidden="true">
                                            <button class="btn btn-info" style="height: 25px; line-height: 10px; color: #344767" >
                                            <i class="fa fa-angle-double-right"></i>
                                            </button>
                                        </span>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>

                </div>
            </div>
       </div>

         <!-- Modal -->
        <div
            class="modal fade"
            id="exampleModal"
            tabindex="-1"
            data-bs-backdrop="static"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                    Control detalle diario
                </h5>
                <button @click="cleanField()"
                    type="button"
                    class="btn-close"
                    id="IdcloseModal1"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                ></button>
                </div>
                <div class="modal-body">
                <div class="form-div col-md-12 col-sm-12 col-xs-12 p-0">
                    <div class="form-div col-md-8 col-sm-8 col-xs-12 p-0">
                    <div class="col-md-12 col-sm-12 col-xs-12 p-0" style="margin-top:10px; border: 1px solid #a5a6bc; border-radius: 5px !important;">
                        <h1 style=" width: 170px; font-size: 12px; margin-top: -10px; margin-left: 7px; background: white; font-weight: 500;margin-bottom: 0px !important; color: #337ab7;">Datos Generales Programación</h1>
                        <div class="form-div col-md-12 col-sm-12 col-xs-12 p-0">
                            <div class="form-div col-md-6 col-sm-6 col-xs-12 p-0">
                                <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
                                    <label class="control-label label-sm" style="font-weight: 700;">Inicio Ruta:</label>
                                </div>
                                <div class="col-md-8 col-sm-8 col-xs-12 separate-in-form">
                                    <label class="control-label label-sm">{{ItemPopup.dateProgramming}}</label>
                                </div>
                            </div>
                            <div class="form-div col-md-6 col-sm-6 col-xs-12 p-0">
                                <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
                                    <label class="control-label label-sm" style="font-weight: 700;">Nro Viaje:</label>
                                </div>
                                <div class="col-md-8 col-sm-8 col-xs-12 separate-in-form">
                                    <label class="control-label label-sm">{{ItemPopup.idProgramming}}</label>
                                </div>
                            </div>
                        </div>
                        <div class="form-div col-md-12 col-sm-12 col-xs-12 p-0">
                            <div class="form-div col-md-6 col-sm-6 col-xs-12 p-0">
                                <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
                                    <label class="control-label label-sm" style="font-weight: 700;">Operación:</label>
                                </div>
                                <div class="col-md-8 col-sm-8 col-xs-12 separate-in-form">
                                    <label class="control-label label-sm">{{ItemPopup.operationHSE}}</label>
                                </div>
                            </div>
                            <div class="form-div col-md-6 col-sm-6 col-xs-12 p-0">
                                <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
                                    <label class="control-label label-sm" style="font-weight: 700;">Conductor:</label>
                                </div>
                                <div class="col-md-8 col-sm-8 col-xs-12 separate-in-form">
                                    <label class="control-label label-sm">{{ItemPopup.DiverPerson1}}</label>
                                </div>
                            </div>
                        </div>
                        <div class="form-div col-md-12 col-sm-12 col-xs-12 p-0">
                            <div class="form-div col-md-6 col-sm-6 col-xs-12 p-0">
                                <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
                                    <label class="control-label label-sm" style="font-weight: 700;">Tracto:</label>
                                </div>
                                <div class="col-md-8 col-sm-8 col-xs-12 separate-in-form">
                                    <label class="control-label label-sm">{{ItemPopup.transportUnit1}}</label>
                                </div>
                            </div>
                            <div class="form-div col-md-6 col-sm-6 col-xs-12 p-0">
                                <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
                                    <label class="control-label label-sm" style="font-weight: 700;">Semi Remolque:</label>
                                </div>
                                <div class="col-md-8 col-sm-8 col-xs-12 separate-in-form">
                                    <label class="control-label label-sm">{{ItemPopup.transportUnit2}}</label>
                                </div>
                            </div>
                        </div>
                        <div class="form-div col-md-12 col-sm-12 col-xs-12 p-0">
                            <div class="form-div col-md-6 col-sm-6 col-xs-12 p-0">
                                <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
                                    <label class="control-label label-sm" style="font-weight: 700;">Ruta:</label>
                                </div>
                                <div class="col-md-8 col-sm-8 col-xs-12 separate-in-form">
                                    <label class="control-label label-sm">{{ItemPopup.origin}} - {{ItemPopup.destination}}</label>
                                </div>
                            </div>
                            <div class="form-div col-md-6 col-sm-6 col-xs-12 p-0">
                                <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
                                    <label class="control-label label-sm" style="font-weight: 700;">Guia:</label>
                                </div>
                                <div class="col-md-8 col-sm-8 col-xs-12 separate-in-form">
                                    <label class="control-label label-sm">{{ItemPopup.despatchAdvice}}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 col-sm-12 col-xs-12 p-0" style="margin-top:20px; border: 1px solid #a5a6bc; border-radius: 5px !important;">
                        <h1 style=" width: 118px; font-size: 12px; margin-top: -10px; margin-left: 7px; background: white; font-weight: 500;margin-bottom: 0px !important; color: #337ab7;">Datos Registro Diario</h1>
                        <div class="form-div col-md-12 col-sm-12 col-xs-12 p-0">
                            <div class="form-div col-md-6 col-sm-6 col-xs-12 p-0">
                                <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
                                    <label class="control-label label-sm" style="font-weight: 700;">Tipo Registro:</label>
                                </div>
                                <div class="col-md-8 col-sm-8 col-xs-12 separate-in-form">
                                    <label class="control-label label-sm">{{ItemPopup.dailyCheckName}}</label>
                                </div>
                            </div>
                            <div class="form-div col-md-6 col-sm-6 col-xs-12 p-0">
                                <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
                                    <label class="control-label label-sm" style="font-weight: 700;">Fecha - Hora - dia:</label>
                                </div>
                                <div class="col-md-8 col-sm-8 col-xs-12 separate-in-form">
                                    <label class="control-label label-sm">{{ItemPopup.dateRegister}} &nbsp;&nbsp;&nbsp; dia 1</label>
                                </div>
                            </div>
                        </div>
                        <div class="form-div col-md-12 col-sm-12 col-xs-12 p-0">
                            <div class="form-div col-md-6 col-sm-6 col-xs-12 p-0">
                                <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
                                    <label class="control-label label-sm" style="font-weight: 700;">Adjunto y/o Evid:</label>
                                </div>
                                <div class="col-md-8 col-sm-8 col-xs-12 separate-in-form">
                                    <button class="btn btn-danger" data-bs-toggle="modal" data-bs-target="#exampleModalImage" style="height: 25px; line-height: 1px;">
                                        
                                        <span style="margin-right: 5px"><i class="fa fa-image"></i></span>Ver Imagen
                                    </button>
                                </div>
                            </div>
                            <div class="form-div col-md-6 col-sm-6 col-xs-12 p-0">
                                <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
                                    <label class="control-label label-sm" style="font-weight: 700;">Observación:</label>
                                </div>
                                <div class="col-md-8 col-sm-8 col-xs-12 separate-in-form">
                                    <input  v-model="filtro.Observacion"
                                            name="Observacion"
                                            class="form-control input-sm"
                                            placeholder="Ingrese Observacion"
                                            ref="refObservacion"
                                            autocomplete="off"/>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 col-sm-12 col-xs-12 p-0" style="margin: 10px 0px !important;">
                            <div class="form-div col-md-12 col-sm-12 col-xs-12 p-0">
                                <div class="col-md-2 col-sm-2 col-xs-12 separate-in-form">
                                    <label class="control-label label-sm" style="font-weight: 700;">Estado:</label>
                                </div>
                                <div class="col-md-2 col-sm-2 col-xs-12 separate-in-form">
                                        <label class="control-label label-sm" style="font-weight: 700; color: #1625d9;">{{ItemPopup.dailyState}}</label>
                                    </div>
                                <div class="form-div col-md-8 col-sm-8 col-xs-12 p-0" v-show="ItemPopup.idState !== 3">
                                    <div class="col-md-6 col-sm-6 col-xs-12 separate-in-form" v-show="ItemPopup.idState === 1">
                                        <button class="btn btn-danger" data-bs-toggle="modal" data-bs-target="#exampleModalRefuse" style="margin-right: 3px !important">
                                            <span style="margin-right: 5px"><i class=""></i></span>OBSERVAR
                                        </button>
                                    </div>
                                    <div class="col-md-6 col-sm-6 col-xs-12 separate-in-form" v-show="ItemPopup.idState === 2 || ItemPopup.idState === 1">
                                        <button @click="getAutorice()" class="btn btn-success" style="margin-right: 3px !important">
                                            <span style="margin-right: 5px"><i class=""></i></span>AUTORIZAR
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="form-div col-md-6 col-sm-6 col-xs-12 p-0">
                                <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
                                    <input v-model="filtro.Popup.Estado" id="IdAutorizado" type="radio" value="2"/>
                                    <label for="IdAutorizado" class="control-label label-sm">&nbsp;&nbsp;Autorizado</label>
                                </div>
                                <div class="col-md-8 col-sm-8 col-xs-12 separate-in-form">
                                    <input v-model="filtro.Popup.Estado" id="IdObservado" type="radio" value="3"/>
                                    <label for="IdObservado" class="control-label label-sm">&nbsp;&nbsp;Observado</label>
                                </div>
                            </div> -->
                        </div>
                        <!-- <div class="col-md-12 col-sm-12 col-xs-12 separate-in-form">
                            <label class="control-label label-sm">Motivo de registro observado (Solo cuando sea observado)</label>
                            <div class="">
                            <textarea v-model="filtro.Observacion"
                                        name="Observacion"
                                        placeholder="Ingrese Observacion"
                                        ref="refObservacion"
                                        autocomplete="off"
                                        rows="4"
                                        style="width: 100%;">
                            </textarea>
                            </div>
                        </div> -->

                    </div>

                    </div>
                    <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
                        <div class=" col-md-12 col-sm-12 col-xs-12 p-l-0 p-r-0 m-t-10 m-b-10 paginate-padding " style="overflow-x: auto; margin-top:10px">
                            <table class=" table table-responsive table-hover table-bordered custom-table ">
                                <thead>
                                    <tr>
                                        <th>OBSERVACIÓN</th>
                                        <th>ESTADO</th>
                                        <!-- <th>FECHA-HORA</th> -->
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, key) in list.Popup.dailAudit" :key="key + 1">
                                        <td class="text-left">{{ item.observation }}</td>
                                        <td class="text-center">{{ item.dailyState }}</td>
                                        <!-- <td class="text-rigth">{{ item.diasFaltantes }}</td> -->
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <hr/>
                
            </div>
            </div>
            </div>
        </div>

        <!-- Modal 2 -->
        <div
            class="modal fade"
            id="exampleModalRefuse"
            tabindex="-1"
            data-bs-backdrop="static"
            aria-labelledby="exampleModalRefuseLabel"
            aria-hidden="true">
            <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                <h5 class="modal-title" id="exampleModalRefuseLabel">
                    Registro Observado
                </h5>
                <button @click="cleanField()"
                    type="button"
                    id="IdCloseModal2"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                ></button>
                </div>
                <div class="modal-body">
                <div class="form-div col-md-12 col-sm-12 col-xs-12 p-0">
                    <div class="form-div col-md-12 col-sm-12 col-xs-12 p-0">
                        <div class="col-md-12 col-sm-12 col-xs-12 separate-in-form">
                            <label class="control-label label-sm">Motivo de registro observado (Solo cuando sea observado)</label>
                            <div class="">
                            <textarea v-model="filtro.Popup2.Observacion"
                                        name="Observacion"
                                        placeholder="Ingrese Observacion"
                                        ref="refObservacionPopup2"
                                        autocomplete="off"
                                        rows="4"
                                        style="width: 100%;">
                            </textarea>
                            </div>
                        </div> 
                    </div>
                </div>
                <hr/>
                
            </div>
            <div class="modal-footer">
                <!-- <button @click="cleanField()" type="button" class="btn btn-danger" id="closeModalRegDia" data-bs-dismiss="modal">
                    <i class="fa fa-close" style="margin-right: 5px"></i>Close
                </button> -->
                <button @click="getRefuse()" type="button" class="btn btn-primary" ref="btnGrabar1">
                    <i class="fa fa-save" style="margin-right: 5px"></i>Grabar
                </button>
            </div>
            </div>
            </div>
        </div>

<!-- Modal 3 -->
<div
    class="modal fade"
    id="exampleModalImage"
    tabindex="-1"
    data-bs-backdrop="static"
    aria-labelledby="exampleModalImageLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-lg">
    <div class="modal-content">
        <div class="modal-header">
        <h5 class="modal-title" id="exampleModalImageLabel">
           Archivo Adjunto
        </h5>
        <button @click="closeImagen()"
            type="button"
            id="IdCloseModaImagen"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
        ></button>
        <button
            data-bs-toggle="modal" 
            data-bs-target="#exampleModal"
            type="button"
            id="IdOpenCSRE"
           style="display: none;"
        ></button>
        </div>
        <div class="modal-body">
                <div class="col-md-12 col-sm-12 col-xs-12 separate-in-form" style="text-align: center;">
                    <template v-if="filtro.Popup.UrlImgArchivoAdj != ''">
                        <img draggable="false" id="IdUrlImgArchivoAdj"  :src="filtro.Popup.UrlImgArchivoAdj" style="width: 80%;"/>
                    </template>
                    <template v-else>
                        <img draggable="false" id="IdUrlImgArchivoAdj" src="../../assets/img/NoImagen.jpg" style="width: 80%;"/>
                    </template>
                </div> 
     </div>
    </div>
    </div>
    </div>

</template>

<script>
  import _ from 'lodash';
  import { APP } from "../../Content/base";

  export default {
    name:"appVueControlesDiarios",
    el: "#appVueControlesDiarios",
    data() {
        return {
        filtro: {
            Desde: '',
            Hasta: '',
            Guia: '',
            Popup: {
                UrlImgArchivoAdj: '',
            },
            Popup2: {
                Observacion: '',
                IdDaily: 0,
            },
        },
        list: {
            Conductor: [],
            TipoRegistro: [],
            Estado: [],
            Operacion:[],
            Popup: {
                dailAudit: [],
            },

            TablaDetalle: [],
        },
        vselect: {
            Conductor: null,
            TipoRegistro: null,
            Estado: null,
            Operacion: null,
            Popup: {
            
            },
        },
        ItemPopup:{

        },
        ItemFila: {},
        paginate: ["items"],
        per: 10,
        NUM_RESULTS: 10, // Numero de resultados por página
        pag: 1, // Página inicial
        vshowSoat: true,
        };
    },
    async mounted() {
        
        await this.geBusqueda();
        await this.getTipRegistro();
        await this.getEstado();
        await this.getOperacion();
    },
    components: {},
    methods: {
        nextFocus: async function (ref, type, time = 100) {
        setTimeout(() => {
            switch (type) {
            case "input":
                ref.focus();
                break;
            case "money":
                ref.$refs.money.focus();
                break;
            case "radio":
                ref.$refs.input.focus();
                break;
            case "check":
                ref.$refs.input.focus();
                break;
            case "combo":
                ref.$refs.search.focus();
                break;
            case "btn":
                ref.focus();
                break;
            default:
                ref();
            }
        }, time);
        },
        geBusqueda: async function () {
            let _this = this;

            this.axios.defaults.headers.common["Authorization"] = "Bearer " + JSON.parse(localStorage.getItem("SessionToken"));

            var request = {
                "FechaInicio": _this.filtro.Desde,
                "FechaFin": _this.filtro.Hasta,
                "IdChofer": _this.vselect.Conductor ? _this.vselect.Conductor.Id : '',
                "IdDailyCheck": _this.vselect.TipoRegistro ? parseInt(_this.vselect.TipoRegistro.Id) : 0,
                "IdState": _this.vselect.Estado ? parseInt(_this.vselect.Estado.Id) : 0,
                "IdOperationHse": _this.vselect.Operacion ? parseInt(_this.vselect.Operacion.Id): 0,
                "Guia": ""
            };

            await this.axios
                .post("api/controlesDiario/SelectControlesDiarios", request)
                .then((res) => {
                if (res.data.EsCorrecto) {

                    _this.list.TablaDetalle = res.data.Valor;

                    if( _this.list.TablaDetalle.length >0){
                    _this.pag = 1
                    }
                
                } else {
                    APP.msg.error(this.$toast, res.data.Mensaje);
                }
                })
                .catch((error) => {
                APP.msg.error(
                    this.$toast,
                    "Token expirado Inicie session..!!" + error
                );
                localStorage.clear();
                this.$store.state.isLogeado = false;
                this.$router.push("/");
                });
        },
        getConductor: async function (search) {
            let _this = this;
          if(search.length > 0){
            this.axios.defaults.headers.common["Authorization"] = "Bearer " + JSON.parse(localStorage.getItem("SessionToken"));

            await APP.Func.delayWhileIsSearching(); 

            await this.axios
                .get("api/netcore/GetConductor" + (search.length > 0 ? ('?search='+search) : '') )
                .then((res) => {
                    if (res.data.EsCorrecto) {

                        _this.list.Conductor = res.data.Valor;

                    } else {
                        APP.msg.error(this.$toast, res.data.Mensaje);
                    }
                    })
                .catch((error) => {
                    APP.msg.error(this.$toast,"Token expirado Inicie session..!!" + error);
                    localStorage.clear();
                    this.$store.state.isLogeado = false;
                    this.$router.push("/");
                });
            }
         },
        getTipRegistro: async function () {
            let _this = this;
          
            this.axios.defaults.headers.common["Authorization"] = "Bearer " + JSON.parse(localStorage.getItem("SessionToken"));

            await this.axios
                .get("api/netcore/GetTipoRegistro")
                .then((res) => {
                    if (res.data.EsCorrecto) {

                        _this.list.TipoRegistro = res.data.Valor;

                    } else {
                        APP.msg.error(this.$toast, res.data.Mensaje);
                    }
                    })
                .catch((error) => {
                    APP.msg.error(this.$toast,"Token expirado Inicie session..!!" + error);
                    localStorage.clear();
                    this.$store.state.isLogeado = false;
                    this.$router.push("/");
                });
        },
        getEstado: async function () {
            let _this = this;
          
            this.axios.defaults.headers.common["Authorization"] = "Bearer " + JSON.parse(localStorage.getItem("SessionToken"));

            await this.axios
                .get("api/netcore/GetEstado")
                .then((res) => {
                    if (res.data.EsCorrecto) {

                        _this.list.Estado = res.data.Valor;

                    } else {
                        APP.msg.error(this.$toast, res.data.Mensaje);
                    }
                    })
                .catch((error) => {
                    APP.msg.error(this.$toast,"Token expirado Inicie session..!!" + error);
                    localStorage.clear();
                    this.$store.state.isLogeado = false;
                    this.$router.push("/");
                });
        },
        getOperacion: async function () {
            let _this = this;
          
            this.axios.defaults.headers.common["Authorization"] = "Bearer " + JSON.parse(localStorage.getItem("SessionToken"));

            await this.axios
                .get("api/netcore/GetOperacion")
                .then((res) => {
                    if (res.data.EsCorrecto) {

                        _this.list.Operacion = res.data.Valor;

                    } else {
                        APP.msg.error(this.$toast, res.data.Mensaje);
                    }
                    })
                .catch((error) => {
                    APP.msg.error(this.$toast,"Token expirado Inicie session..!!" + error);
                    localStorage.clear();
                    this.$store.state.isLogeado = false;
                    this.$router.push("/");
                });
        },
        getOpenDailyControles: async function (_Item) {
           let _this = this;
          
            //_this.CleanPopUps();
            this.axios.defaults.headers.common["Authorization"] = "Bearer " + JSON.parse(localStorage.getItem("SessionToken"));

            _this.filtro.Popup2.IdDaily = _Item.IdDaily;
            _this.ItemFila = _Item;

            await this.axios
                .get("api/controlesDiario/get/" + (_Item.IdDaily > 0 ? String(_Item.IdDaily) : ''))
                .then((res) => {
                    if (res.data.EsCorrecto) {

                       var _Data = _.cloneDeep(res.data.Valor);

                       if(_Data){
                            _this.ItemPopup = _Data;

                            for (var i = 0; i < _Data.dailyParam.length ; i++) {
                               if(_Data.dailyParam[i].codParam === 1){
                                _this.filtro.Popup.UrlImgArchivoAdj = _Data.dailyParam[i].valueParam;
                               }
                            }
                                    
                            _this.list.Popup.dailAudit = _.cloneDeep(_Data.dailAudit);

                            document.getElementById('IdOpenModal').click();
                       }
                       
                    } else {
                        APP.msg.error(this.$toast, res.data.Mensaje);
                    }
                    })
                .catch((error) => {
                    APP.msg.error(this.$toast,"Token expirado Inicie session..!!" + error);
                    localStorage.clear();
                    this.$store.state.isLogeado = false;
                    this.$router.push("/");
                });
        },
        getRefuse: async function () {
            let _this = this;

            if(_this.filtro.Popup2.Observacion != ''){
                this.axios.defaults.headers.common["Authorization"] = "Bearer " + JSON.parse(localStorage.getItem("SessionToken"));
                var usuario = JSON.parse(localStorage.getItem("Usuario"));

                var request = {
                    "idDaily": parseInt(_this.filtro.Popup2.IdDaily) || 0,
                    "usrRegister": usuario ? parseInt(usuario.UsuarioId) : 0,
                    "reason": _this.filtro.Popup2.Observacion || '',
                };

                await this.axios
                    .post("api/controlesDiario/refuse", request)
                    .then((res) => {
                        if (res.data.EsCorrecto) {
                            document.getElementById("IdCloseModal2").click();
                            APP.msg.success( this.$toast, "Se Observo correctamente..!!");
                        
                        } else {
                            APP.msg.error(this.$toast, res.data.Mensaje);
                        }
                    })
                    .catch((error) => {
                    APP.msg.error( this.$toast, "Token expirado Inicie session..!!" + error );
                    localStorage.clear();
                    this.$store.state.isLogeado = false;
                    this.$router.push("/");
                    });

            }else{
                APP.msg.warning(this.$toast, "Ingrese Observación...!");
                _this.nextFocus(this.$refs.refObservacionPopup2, 'input', 200);
            }

        },
        CleanPopUps: function(){
           let _this = this;

           _this.ItemPopup = {};
           _this.filtro.Popup2.Observacion = '';
           _this.filtro.Popup2.IdDaily = 0;
        },
        getAutorice: async function () {
            let _this = this;

                this.axios.defaults.headers.common["Authorization"] = "Bearer " + JSON.parse(localStorage.getItem("SessionToken"));
                var usuario = JSON.parse(localStorage.getItem("Usuario"));

                var request = {
                    "idDaily":  parseInt(_this.filtro.Popup2.IdDaily) || 0,
                    "usrRegister": usuario ? parseInt(usuario.UsuarioId) : 0,
                };

                await this.axios
                    .post("api/controlesDiario/authorize", request)
                    .then((res) => {
                        if (res.data.EsCorrecto) {
                            document.getElementById("IdcloseModal1").click();
                            APP.msg.success( this.$toast, "Se autorizo correctamente..!!");

                        } else {
                            APP.msg.error(this.$toast, res.data.Mensaje);
                        }
                    })
                    .catch((error) => {
                    APP.msg.error( this.$toast, "Token expirado Inicie session..!!" + error );
                    localStorage.clear();
                    this.$store.state.isLogeado = false;
                    this.$router.push("/");
                    });


        },
        cleanField: async function(){
            let _this = this;
                _this.ItemFila = {};
                _this.filtro.Popup.UrlImgArchivoAdj= '';
        },
        closeImagen: async function(){
                await this.getOpenDailyControles(this.ItemFila);
                document.getElementById('IdOpenCSRE').click();
        },
        refrescar: async function(){
          let _this = this;

          _this.filtro.Desde = '';
          _this.filtro.Hasta = '';
          _this.filtro.Guia = '';
          _this.vselect.Conductor = null;
          _this.vselect.TipoRegistro = null;
          _this.vselect.Estado = null;
          _this.vselect.Operacion = null;
          
          await _this.geBusqueda();
        },
    },
    watch: {},
    }
</script>

<style scoped>

</style>