<template>
  <!-- <header class="p-3  border-bottom" style="background-color:rgb(52, 71, 103) !important;margin: 0px !important;"> -->
  <header class="p-3  border-bottom" style="background-color:rgb(52, 71, 103) !important;margin: 0px !important;">
    <div class="container" style="max-width: 100% !important; margin: 0px !important; padding: 0px !important;">
      <div class="form-div col-md-12 col-sm-12 justify-content-center justify-content-lg-start">
        <div class="col-md-6 col-sm-6"></div>

      </div>

      <div
        class=" d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start" >

        <li class="px-3 nav-item d-flex align-items-center">
            <a class="p-0 nav-link text-white" @click="toggleSidenavVer">
              <i class="fa fa-align-justify fa-2x "></i>
            </a>
          </li>
        <ul class="nav col-12 col-lg-auto me-lg-auto  mb-2 justify-content-center  mb-md-0 verLista"  >
          <!-- <li><a href="#" style="color:white !important" class="nav-link px-2 link-secondary">Overview</a></li>
          <li><a href="#" style="color:white !important" class="nav-link px-2 link-dark">Inventory</a></li>
          <li><a href="#" style="color:white !important" class="nav-link px-2 link-dark">Customers</a></li>
          <li><a href="#" style="color:white !important" class="nav-link px-2 link-dark">Products</a></li> -->
        </ul>

        <form class="col-12 col-lg-auto mb-3 mb-lg-0 me-lg-3 verLista" role="search">
          <!-- <input
            type="search"
            class="form-control"
            placeholder="Search..."
            aria-label="Search"
          /> -->
        </form>

        <div class="dropdown text-end sep" style="">
          <a
            href="#"
            class="d-block link-dark text-decoration-none dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            style="color: white !important;"
          ><span><i class="fa fa-laptop" style="margin-right: 5px;"></i>{{ this.$store.state.Usuario }}</span>
            <!-- <img
              src="https://github.com/mdo.png"
              alt="mdo"
              width="32"
              height="32"
              class="rounded-circle"
            /> -->
          </a>
          <ul class="dropdown-menu text-small">
            <li><a class="dropdown-item" href="#">Configuración</a></li> 
            <li><hr class="dropdown-divider" /></li>
            <li><a class="dropdown-item text-primary" href="#" @click="salir()"><i class="fa fa-lock " style="margin-right: 5px;"></i>Cerrar sesión</a></li>
          </ul>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapMutations } from "vuex";

export default {
    name: "LayoutHeader",
  props: {},
  data() {
    return {
      //User: "",
    };
  },
  mounted() {
    // let _this = this;

    // var usuario = JSON.parse(localStorage.getItem("Usuario"));
    // if(usuario && usuario.Usuario != '')
    //   _this.User = usuario.Usuario;
   
  },
  components: {},
  methods: {
    ...mapMutations(["toggleSidenavVer"]),
    salir: async function(){
      localStorage.clear();
      this.$store.state.isLogeado = false;
      this.$router.push("/login");
    }
    

  },
};
</script>

<style scoped>

.sep{
    margin-right: 10px !important;
    left: 0% !important;
  }

@media (max-width:767px){
  .justify-content-center {
    justify-content: left !important;
  } 

  .sep{
    left: 73% !important;
  }
}


</style>