<template>
   <div class="Container">
            <div style="margin-bottom: 5px !important">
              <button class="btn btn-success" style="margin-right: 3px !important" data-bs-toggle="modal" data-bs-target="#exampleModalContHoraSu">
                <span style="margin-right: 5px"><i class="fa fa-arrow-right"></i></span>Nuevo
              </button>
              <button class="btn btn-primary" style="margin-right: 3px !important">
                <span style="margin-right: 5px"><i class="fa fa-search"></i></span>Buscar
              </button>
            </div>
            <div class="White-Box">
                <div class="form-div" style="margin-bottom: 10px">
                    <div class="col-md-2 col-sm-2 col-xs-12 separate-in-form">
                        <label class="control-label label-sm">Desde</label>
                        <div class="">
                            <input  v-model="filtro.Desde"
                                    name="Desde"
                                    class="form-control input-sm"
                                    placeholder="Ingrese Descripción"
                                    ref="refDesde"
                                    type="date"
                                    autocomplete="off"/>
                        </div>
                    </div>
                    <div class="col-md-2 col-sm-2 col-xs-12 separate-in-form">
                        <label class="control-label label-sm">Hasta</label>
                        <div class="">
                            <input  v-model="filtro.Hasta"
                                    name="Hasta"
                                    class="form-control input-sm"
                                    placeholder="Ingrese Descripción"
                                    ref="refHasta"
                                    type="date"
                                    autocomplete="off"/>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
                        <label class="control-label label-sm">Conductor</label>
                        <div class="">
                            <v-select
                                v-model="vselect.Conductor"
                                name="Conductor"
                                :clearable="false"
                                class="v-select-form"
                                label="Descripcion"
                                placeholder="--Seleccione--"
                                :options="list.Conductor"
                                ref="refConductor">
                            </v-select>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
                        <label class="control-label label-sm">Operación</label>
                        <div class="">
                            <v-select
                                v-model="vselect.Operacion"
                                name="Operacion"
                                :clearable="false"
                                class="v-select-form"
                                label="Descripcion"
                                placeholder="--Seleccione--"
                                :options="list.Operacion"
                                ref="refOperacion">
                            </v-select>
                        </div>
                    </div>
                </div>
                <div class="form-div" style="margin-bottom: 10px">
                    <div class="col-md-3 col-sm-3 col-xs-12 separate-in-form">
                        <label class="control-label label-sm">Riesgo</label>
                        <div class="">
                            <v-select
                                v-model="vselect.Riesgo"
                                name="Riesgo"
                                :clearable="false"
                                class="v-select-form"
                                label="Descripcion"
                                placeholder="--Seleccione--"
                                :options="list.Riesgo"
                                ref="refRiesgo">
                            </v-select>
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-3 col-xs-12 separate-in-form">
                        <label class="control-label label-sm">Estado</label>
                        <div class="">
                            <v-select
                                v-model="vselect.Estado"
                                name="Estado"
                                :clearable="false"
                                class="v-select-form"
                                label="Descripcion"
                                placeholder="--Seleccione--"
                                :options="list.Estado"
                                ref="refEstado">
                            </v-select>
                        </div>
                    </div>
                    
                </div>
                <div class="row">
                    <div class=" col-md-12 col-sm-12 col-xs-12  p-l-0 p-r-0 m-b-10 paginate-padding" style="overflow-x: auto" >
                    <table class="  table table-responsive table-hover table-bordered  custom-table" >
                        <thead>
                            <tr>
                                <th>FECHA</th>
                                <th>OPERACIÓN</th>
                                <th>RESPONSABLE</th>
                                <th>DESCRIPCIÓN HALLAZGO</th>
                                <th>RIESGO</th>
                                <th>% AVANCE</th>
                                <th>ESTADO</th>
                                <th>EVIDENCIA</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr 
                            v-for="(item, key) in list.TablaDetalle" :key="key + 1"
                            v-show="(pag - 1) * NUM_RESULTS <= key && pag * NUM_RESULTS > key">
                                <td class="text-left">{{ item.Empresa }}</td>
                                <td class="text-center">{{ item.Placa }}</td>
                                <td class="text-left">{{ item.NroPoliza }}</td>
                                <td class="text-left">{{ item.Aseguradora }}</td>
                                <td class="text-left">{{ item.VigenciaAl }}</td>
                            </tr>
                        </tbody>
                    </table>
                    </div>
                    <div class="col-md-12 col-sm-12 col-xs-12 p-0" style="margin-top: 10px !important" >
                        <nav aria-label="Page navigation" class="text-center">
                            <ul class="pagination text-center pull-right">
                                <li>
                                    <a href="#" aria-label="Previous" v-show="pag != 1" @click.prevent="pag -= 1" style="padding: 0px 0px 0px 0px" >
                                        <span aria-hidden="true">
                                            <button class="btn btn-info" style="height: 25px; line-height: 10px; color: #344767">
                                            <i class="fa fa-angle-double-left"></i>
                                            </button>
                                        </span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#" aria-label="Next" v-show="(pag * NUM_RESULTS) / list.TablaDetalle.length < 1" @click.prevent="pag += 1" style="padding: 0px 10px 0px 5px" >
                                        <span aria-hidden="true">
                                            <button class="btn btn-info" style="height: 25px; line-height: 10px; color: #344767" >
                                            <i class="fa fa-angle-double-right"></i>
                                            </button>
                                        </span>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>

                </div>
            </div>
    </div>
</template>

<script>
    export default {
        name: "appVueRegistroActosRacs",
        el: "#appVueRegistroActosRacs",
        data() {
            return {
            filtro: {
            
                Popup: {
                },
            },
            list: {
                Popup: {
                
                },

                TablaDetalle: [],
            },
            vselect: {
                Popup: {
                
                },
            },
            paginate: ["items"],
            per: 10,
            NUM_RESULTS: 10, // Numero de resultados por página
            pag: 1, // Página inicial
            vshowSoat: true,
            };
        },
        async mounted() {
            
            // await _this.getEmpresa();
            // await _this.getAseguradora();
        },
        components: {},
        methods: {
            nextFocus: async function (ref, type, time = 100) {
            setTimeout(() => {
                switch (type) {
                case "input":
                    ref.focus();
                    break;
                case "money":
                    ref.$refs.money.focus();
                    break;
                case "radio":
                    ref.$refs.input.focus();
                    break;
                case "check":
                    ref.$refs.input.focus();
                    break;
                case "combo":
                    ref.$refs.search.focus();
                    break;
                case "btn":
                    ref.focus();
                    break;
                default:
                    ref();
                }
            }, time);
            },
        },
        watch: {},
    }
</script>

<style lang="scss" scoped>

</style>